import React, { Fragment } from "react";
import classes from "./Layout.module.scss";

const Layout = (props) => {
  return <div>
     
     <Fragment>{props.children}</Fragment>;
  </div>
};

export default Layout;
