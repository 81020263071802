import React from "react";
import classes from "./Product.module.scss";
import fare from '../../../../../../src/assets/imgs/home/1.jpg'
const Product = (props) => {
  return (
    <div className={classes.Product}>
    <img src={fare} loading="lazy" ></img>
    <p className={classes.fare}>
       <b>发热电缆</b>
   <span>瑞海热控严选市面上热效率最高的发热电缆设备,采用世界最前沿的高灵敏度的合金电阻材料或碳纤维发热材料,可适用于各种应用场景。将发热电缆预埋在指定供暖区域，通电使发热电缆发热,将舒适、持续、稳定的热量以热传导(对流)的方式输送给用户,整体经济环保。</span></p>
    </div>
  );
};

export default Product;
