import React from "react";
import classes from "./solutionsPage.module.scss";
import cables from "../../assets/cables.png";
import Layout from "../../hoc/Layout/Layout";
import Jumbotron from "../../components/Jumbotron/Jumbotron";
import { Link } from "react-router-dom";
import 大型商场会议中心 from "../../assets/大型商场会议中心.jpg";


const solutions = () => {
  return (
      <Layout>
        <Jumbotron page="subPage" />
        <main className={classes.Main}>
          <div className={classes.ProductContainer}>
            <h1>解决方案</h1>
            {/* to do scroll up */}
            <a className={classes.Anchor} id="solutions"></a>
            <ul className={classes.ProductList}>
              <li className={classes.ProductItem}>
                <Link to="/solutions/hospital">医院</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/school">学校</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/office">机关单位、写字楼</Link>
              </li>
              <li className={classes.ProductItem} style={{border: "1px #f56a00 solid"}}>
                <Link to="/solutions/mall"  style={{color:"#f56a00"}}>大型商场、会议中心</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/tunnel">管道电伴热</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/roof">屋面除雪融冰</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/road">道路除雪融冰</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/runway">机场跑道融雪除冰</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/firefighting">消防电伴热</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/unit">居民住宅</Link>
              </li>
            </ul>
          </div>
          <section className={classes.CableSection}>
            <div className={classes.Header}>
              <a className={classes.Anchor} id="advanage"></a>
              <h2>大型商场会议中心</h2>
              <hr />
            </div>

            <div className={classes.Text}>
              <img src={大型商场会议中心} style={{margin:'0 15%',marginTop:'18px', width: '70%'}}/>

              <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
                需求特点：人流量大、时效性强、占地面积大以及较高的经济效益和运行的稳定。
              </p>
              <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
                解决方案：热源采用瑞海热控螺杆空压机组配合瑞海智能热控系统。

              </p>

              <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
                热源采用螺杆空压机组，冷热两连供，单机采暖、制冷可覆盖10000平方米。配合风机盘管，温控调节速度更快。基于瑞海热控的温控网络，随空间温度调整空气源热泵功率和出水口温度，降低热损耗，运行效率更高，运行成本更低。配合接入瑞海数据综合控制系统，有效的实现室内温度、各个管道的水温、压力等数据的实时监测，自动运行、故障报警、数据记录，保障稳定性。
              </p>


            </div>
          </section>
        </main>
      </Layout>
  );
};

export default solutions;