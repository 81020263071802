import React from "react";
import Layout from "../../hoc/Layout/Layout";
import classes from "./aboutPage.module.scss";
import Jumbotron from "../../components/Jumbotron/Jumbotron";
import certs from "../../assets/aboutPage/公司资质.jpg";
import parterns from "../../assets/aboutPage/cooperation.jpg";
import company from "../../assets/aboutPage/guanyu.jpg";
import xuke from "../../assets/imgs/gyu/2.jpg";
import pinpai from "../../assets/imgs/gyu/1.jpg";
import qingjie from "../../assets/imgs/gyu/3.jpg";
import zhuangli from "../../assets/imgs/gyu/4.jpg";
import peix from "../../assets/aboutPage/peix.png";
import  qingjiangming from '../../assets/aboutPage/3_03.png'
import qingyang from '../../assets/aboutPage/2_04.png'
import wenzhifeng from '../../assets/aboutPage/3_05.png'
import {Administration,management,  position,position1,position2,position3} from '../../common/personnel.js'
class aboutPage extends React.Component {
 constructor(props) {
  super(props)
  this.state = {
   Adminindex: -1,
   managementindex:-1,
   userindex: -1,
   technologyoverindex: -1,
   Designoverindex: -1,
   saleoverindex:-1
   
  }
 }
  
 Administration(index) {
  this.setState({
     Adminindex:index
    })
 }
 Administration_out() {
   this.setState({
     Adminindex:-1
    })
 }
 management(index) {
   this.setState({
     managementindex:index
    })
 }
 management_out() {
    this.setState({
     managementindex:-1
    })
 }
 personover(index) {
  this.setState({
  userindex:index
 })
 }
 personout(index) {
    this.setState({
     userindex:-1
 })
 }
 technologyover(index) {
  this.setState({
    technologyoverindex:index
   })
 }
 technologyout() {
   this.setState({
    technologyoverindex:-1
   })
 }
 Designover(index) {
  this.setState({
      Designoverindex:index
    })
 }
 Designoverout() {
    this.setState({
      Designoverindex:-1
    })
 }
 saleover(index) {
  this.setState({
    saleoverindex:index
   })
 }
 saleout() {
    this.setState({
    saleoverindex:-1
   })
 }
 render() {
  const {Adminindex,managementindex, userindex,technologyoverindex,Designoverindex,saleoverindex } = this.state
    return (
    <Layout>
      <Jumbotron page="subPage" />
      <main className={classes.Main}>
        <div className={classes.AboutContainer}>
          <h1>关于我们</h1>
          <p>公司愿景：依托云平台控制方案，为客户打造最舒适、便捷的生活环境</p>
        </div>
        <section className={classes.GallerySection}>
          <div className={classes.Header}>
            <h2>关于公司</h2>
            <hr />
          </div>
          <div className={classes.Text}>
            <div className={classes.left}>
               <p>
            瑞海热控（山西瑞海节能网控电热集中供暖有限公司）于2009年成立于山西太原，扎根集中供暖行业十余年。依托自主研发的互联网云控制系统与物联网概念的应用，逐步由传统领域向智能化转型，致力于改变传统集中供暖的落后非环保方式，从低碳环保领域出发，为客户提供智能化集中供暖、恒温控制、能源管理与碳能管理等综合一体化服务。
            </p>
            <p className={classes.text_tab}>
              改变现有市场上常见的控制系统只能控制单一系统，无法统一集成化、智能化、可视化操控的弊端。依托云平台及物联网的数据兼容和交换实现多平台数据整合交融。配合移动端数据交换，实时显示、信息互动，极大的方便了用户操控，摒弃了传统的固定式人为控制的弊端。
            </p>
            <p>
                公司作为太原市首家获得供暖特许经营权的企业（证书编号20120001），拥有7项发明专利，13项实用新型专利。在全国范围内，开展了综合集中供暖业务。先后服务于“国家电网”“同煤集团”“太原学院”“北京旅游局”等中大型客户，热力覆盖面积达100万平方米以上。能够一站式满足采暖方案设计、施工、调试质检、运营维护等需求。为推动分布式热源和传统行业智慧赋能发挥出优势作用。
            </p>
            </div>
            <div  className={classes.right}>
               <img  className={classes.about_img} src={company} loading="lazy"></img>
            </div>

          </div>
        </section>
        <section className={classes.GallerySection}>
          <div className={classes.Header}>
            <h2>公司资质</h2>
            <hr />
          </div>
            <div className={classes.Franchise}>
            <ul>

             <li> <img src={xuke}></img>   <span>作为太原首家拥有特许经营权资质的供暖企业,改变了传统粗放式"一刀切"的供热模式,致力于依托信息化,提供更加绿色、高效、多元的新能源供热体系服务</span> </li>

              <li> <img src={pinpai}></img>   <span>由中华民族建筑研究会供热采暖委员会及《暖通空调》杂志社、中国建筑节能减排产业联盟颁发</span> </li>
        <li> <img src={qingjie}></img>   <span>由中国民族建筑研究会供暖采暖委员会审核颁发，见证了瑞海智慧校园清洁供暖技术的成熟,对清洁能源进校园的推广具有划时代意义。瑞海技术中心可针对全国范围内校园清洁供暖项目进行评估并提出指导性意见，提供综合测评平台</span> </li>
        <li> <img className={classes.pic} src={peix}></img>   <span className={classes.tanzc}>瑞海积极参与国家碳中和战略，为清洁供暖和暖通产业低碳化改造提供了多项服务。目前公司多人已获得国家人社局考核认证的碳资产管理证书，且与国网公司及轨道交通下属国碳公司达成合作，未来将携手推进碳市场在山西的整体构建。</span> </li>
            </ul>
             <ol className={classes.zhuangli}> <li><img   src={zhuangli}></img></li> </ol>
          </div>

        </section>
        <section className={classes.GallerySection}>
          <div className={classes.Header}>
            <h2>核心团队</h2>
            <hr />
          </div>
        <div className={classes.tuandui}>
 
         <duv className={classes.tuandui_right}>
          <ul>
             <h5>管理团队</h5>
          {Administration.length && Administration.map((val, index) => {
           return <li>
           
             <p>
              <img onMouseOver={() => {
              this.Administration(index)
             }} onMouseOut={() => {
                this.Administration_out()
               }} src={val.img}></img>
               <b  onMouseOver={() => {
              this.Administration(index)
             }} onMouseOut={() => {
                this.Administration_out()
               }}>{val.name}</b>
             <b  onMouseOver={() => {
              this.Administration(index)
             }} onMouseOut={() => {
                this.Administration_out()
               }}>{val.post}</b>
               <span className={Adminindex==index?classes.activeshow:classes.activehide}>{ val.text}</span>
             </p>
           
            </li>
            
          })}
         </ul>
            <ul>
             <h3>战略咨询团队</h3>
          {management.length && management.map((val, index) => {
           return   <li>
             <p>
              <img   onMouseOver={() => {
              this.management(index)
             }} onMouseOut={() => {
                this.management_out()
               }} src={val.img}></img>
               <b  onMouseOver={() => {
              this.management(index)
             }} onMouseOut={() => {
                this.management_out()
               }}>{val.name}</b>
             <b  onMouseOver={() => {
              this.management(index)
             }} onMouseOut={() => {
                this.management_out()
               }}>{val.post}</b>
               <span className={managementindex==index?classes.activeshow:classes.activehide}>{ val.text}</span>
             </p>
           
            </li>
            
          })}
         </ul>
          <ul>
             <h3>硬件开发团队</h3>
          {position.length && position.map((val, index) => {
           return   <li>
             <p>
              <img   onMouseOver={() => {
              this.personover(index)
             }} onMouseOut={() => {
                this.personout()
               }} src={val.img}></img>
               <b  onMouseOver={() => {
              this.personover(index)
             }} onMouseOut={() => {
                this.personout()
               }}>{val.name}</b>
             <b  onMouseOver={() => {
              this.personover(index)
             }} onMouseOut={() => {
                this.personout()
               }}>{val.post}</b>
               <span className={userindex==index?classes.activeshow:classes.activehide}>{ val.text}</span>
             </p>
           
            </li>
            
          })}
          </ul>
            <ul>
             <h3>云技术开发团队</h3>
          {position1.length && position1.map((val, index) => {
           return   <li>
             <p>
              <img   onMouseOver={() => {
              this.technologyover(index)
             }} onMouseOut={() => {
                this.technologyout()
               }} src={val.img}></img>
               <b  onMouseOver={() => {
              this.technologyover(index)
             }} onMouseOut={() => {
                this.technologyout()
               }}>{val.name}</b>
             <b  onMouseOver={() => {
              this.technologyover(index)
             }} onMouseOut={() => {
                this.technologyout()
               }}>{val.post}</b>
               <span className={technologyoverindex==index?classes.activeshow:classes.activehide}>{ val.text}</span>
             </p>
           
            </li>
            
          })}
          </ul>
            <ul>
             <h3>设计团队</h3>
          {position2.length && position2.map((val, index) => {
           return   <li>
             <p>
              <img   onMouseOver={() => {
              this.Designover(index)
             }} onMouseOut={() => {
                this.Designoverout()
               }} src={val.img}></img>
               <b  onMouseOver={() => {
              this.Designover(index)
             }} onMouseOut={() => {
                this.Designoverout()
               }}>{val.name}</b>
             <b  onMouseOver={() => {
              this.Designover(index)
             }} onMouseOut={() => {
                this.Designoverout()
               }}>{val.post}</b>
               <span className={Designoverindex==index?classes.activeshow:classes.activehide}>{ val.text}</span>
             </p>
           
            </li>
            
          })}
          </ul>
             <ul>
             <h5>销售团队</h5>
          {position3.length && position3.map((val, index) => {
           return   <li>
             <p>
              <img   onMouseOver={() => {
              this.saleover(index)
             }} onMouseOut={() => {
                this.saleout()
               }} src={val.img}></img>
               <b  onMouseOver={() => {
              this.saleover(index)
             }} onMouseOut={() => {
                this.saleout()
               }}>{val.name}</b>
             <b  onMouseOver={() => {
              this.saleover(index)
             }} onMouseOut={() => {
                this.saleout()
               }}>{val.post}</b>
               <span className={saleoverindex==index?classes.activeshow:classes.activehide}>{ val.text}</span>
             </p>
           
            </li>
            
          })}
         </ul>
            </duv>
          </div>
        </section>
        <section className={classes.GallerySection}>
          <div className={classes.Header}>
            <h2>合作伙伴</h2>
            <hr />
          </div>
          <div className={classes.Gallery}>
            <img src={parterns} style={{marginLeft:"15px"}}/>
          </div>
        </section>

      </main>

    </Layout>
  );
 }
};

export default aboutPage;
