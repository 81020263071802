import React from "react";
import classes from "./solutionsPage.module.scss";
import cables from "../../assets/cables.png";
import Layout from "../../hoc/Layout/Layout";
import Jumbotron from "../../components/Jumbotron/Jumbotron";
import { Link } from "react-router-dom";
import 医院综合热能解决方案1 from "../../assets/医院综合热能解决方案1.jpg";
import 医院综合热能解决方案2 from "../../assets/医院综合热能解决方案2.jpg";

const solutions = () => {
  return (
      <Layout>
        <Jumbotron page="subPage" />
        <main className={classes.Main}>
          <div className={classes.ProductContainer}>
            <h1>解决方案</h1>
            {/* to do scroll up */}
            <a className={classes.Anchor} id="solutions"></a>
            <ul className={classes.ProductList}>
              <li className={classes.ProductItem} style={{border: "1px #f56a00 solid"}}>
                <Link to="/solutions/hospital" style={{color:"#f56a00"}}>医院</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/school">学校</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/office">机关单位、写字楼</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/mall">大型商场、会议中心</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/tunnel">管道电伴热</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/roof">屋面除雪融冰</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/road">道路除雪融冰</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/runway">机场跑道融雪除冰</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/firefighting">消防电伴热</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/unit">居民住宅</Link>
              </li>
            </ul>
          </div>
          <section className={classes.CableSection}>
            <div className={classes.Header}>
              <a className={classes.Anchor} id="advanage"></a>
              <h2>医院综合热能解决方案</h2>
              <hr />
            </div>

            <div className={classes.Text}>
              <img src={医院综合热能解决方案1} style={{margin:'0 15%',marginTop:'18px', width: '70%'}}/>

              <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
                需求特点：人流量大、用热（冷）时间长、不同用途用热差异大、杜绝病毒在各个房间之间传播造成交叉感染。
              </p>
              <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
                解决方案：发热电缆、燃气锅炉配合瑞海智能热控系统。热传递模式选用电地暖热辐射，暖气片，水管传热。
              </p>

              <img src={医院综合热能解决方案2} style={{margin:'0 15%',marginTop:'18px', width: '70%'}}/>

              <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
                热源为（单、双导）发热电缆。施工简单，工期短，直接利用能源、热效率高。房间之间没有类似于中央空调一样的整体空气交换，杜绝了特殊病房之间传播造成交叉感染。配套互联网计算机集中控制系统，能精确控温，根据每个用热部位要求不同随时改变用热温度及时间。如果有热水和蒸汽需求，可以配合使用电锅炉提供专项服务。同时在各个出入口安装二层隔热间与空气帘。减少患者接触门把手造成感染的同时，有效减少热损耗。辅助以（预混冷凝）燃气锅炉，能在满足正常用热需求的基础上，同时满足提供热水、蒸汽的需求。配合接入瑞海数据综合控制系统，有效的实现室内温度、各个管道的水温、压力等数据的实时监测，配合多点位控制阀门开闭的先导性节能方式，有效降低能耗。热源功率可实时变频调整，能够实现运行自动化、故障报警、数据记录等功能。
              </p>

            </div>
          </section>
        </main>
      </Layout>
  );
};

export default solutions;