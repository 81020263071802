import React, { useState } from "react";
import classes from "./Products.module.scss";
import Product from "./Product/Product";
import img1 from "../../../../assets/1.png";
import img2 from "../../../../assets/2.png";
import img3 from "../../../../assets/3.png";
import img4 from "../../../../assets/4.png";
import img5 from "../../../../assets/5.png";
import img6 from "../../../../assets/6.png";
import tunnel1 from "../../../../assets/tunnel1.png";
import tunnel2 from "../../../../assets/tunnel2.png";
import kongQiClassroom from "../../../../assets/kongQiClassroom.png";
import kongQiArchitecture from "../../../../assets/kongQiArchitecture.png";
import kongQiHospital from "../../../../assets/kongQiHosptial.png";
import ranQiGuoHospital from "../../../../assets/ranQiGuoHospital.png";
import ranQiGuoClassroom from "../../../../assets/ranQiGuoClassroom.png";

import ProductDIanBanRe from "./ProductDianBanRe/ProductDianBanRe";
import ProductKongQiYuan from "./ProductKongQiYuan/ProductKongQiYuan";
import ProductRanQiGuo from "./ProductRanQiGuo/ProductRanQiGuo";

const Products = (props) => {
  const [state, setState] = useState({
    index: 0,
  });
  const captions = [
    "温控精准、计费科学、舒适度高",
    "响应迅速、自动化运行、经济效益高",
    "供热稳定、温控精确、舒适度高",
    "响应迅速、承压高、使用寿命长",
    "温控精准，防止交叉感染",
    "响应迅速、自动化运行、使用寿命长",
  ];
  const imgs = [img1, img2, img3, img4, img5, img6];
  const texts = [
    "民用住宅",
    "校园综合",
    "商业空间",
    "跑道除雪",
    "医院",
    "隧道除雪",
  ];
  const cablePaths = [
    "/solutions/unit",
    "/solutions/school",
    "/solutions/office",
    "/solutions/runway",
    "/solutions/hospital",
    "/solutions/tunnel",
  ];
  // const products = imgs.map((img, index) => {
  //   return (
  //     <Product
  //       key={index}
  //       img={img}
  //       caption={captions[index]}
  //       text={texts[index]}
  //       path={cablePaths[index]}
  //     />
  //   );
  // });
  const dianBanReImg = [tunnel1, tunnel2];
  const dianBanReTexts = ["管道电伴热", "消防电伴热"];
  const dianBanReCaptions = [
    [
      "需求特点：在环境寒冷下，需要保证管道内液体的温度，长（短）距离防冻保温、伴热均匀，能根据管道的形状排布",
      "解决方案：热源采用瑞海自限温电伴热带或者恒功率伴热带配合瑞海智能热控系统",
    ],
    [
      "需求特点：长时间自动管理运行，功耗低，管道距离长",
      "解决方案：热源采用瑞海自限温电伴热带配合瑞海智能热控系统和瑞海场景冰雪气象监测技术",
    ],
  ];
  const dianBanRePaths = ["/solutions/tunnel", "/solutions/firefighting"];
  // const productsDianBanRe = dianBanReImg.map((img, index) => {
  //   return (
  //     <ProductDIanBanRe
  //       key={index}
  //       img={img}
  //       caption={dianBanReCaptions[index]}
  //       text={dianBanReTexts[index]}
  //       index={index}
  //       path={dianBanRePaths[index]}
  //     />
  //   );
  // });

  const kongQiYuanImg = [kongQiClassroom, kongQiArchitecture, kongQiHospital];
  const kongQiYuanTexts = ["校园", "购物中心", "医院"];
  const kongQiYuanCaptions = [
    "响应迅速，自动化运行，经济效益高",
    "响应迅速、承压高、使用寿命长",
    "温控精准，防止交叉感染",
  ];
  const kongQiYuanPahs = [
    "/solutions/school",
    "/solutions/mall",
    "/solutions/hospital",
  ];
  // const productsKongQiYuan = kongQiYuanImg.map((img, index) => {
  //   return (
  //     <ProductKongQiYuan
  //       key={index}
  //       img={img}
  //       caption={kongQiYuanCaptions[index]}
  //       text={kongQiYuanTexts[index]}
  //       index={index}
  //       path={kongQiYuanPahs[index]}
  //     />
  //   );
  // });

  const ranQiGuoImg = [ranQiGuoClassroom, ranQiGuoHospital];
  const ranQiGuoTexts = ["校园", "医院"];
  const ranQiGuoCaptions = [
    [
      "需求特点：人员流动性大、用热时效性强带来的供热需求复杂、楼宇种类较多，以及需要考虑整体运行的经济性",
      "解决方案：热源采用瑞海热控发热电缆、瑞海空气源热泵、瑞海天然气锅炉配合瑞海智能热控系统的建筑集群控制技术",
    ],
    [
      "需求特点：人流量大、用热（冷）时间长、不同用途用热差异大、杜绝病毒在各个房间之间传播造成交叉感染",
      "解决方案：发热电缆、燃气锅炉配合瑞海智能热控系统。热传递模式选用电地暖热辐射，暖气片，水管传热",
    ],
  ];
  const ranQiGuoPahs = ["/solutions/school", "/solutions/hospital"];
  // const productsRanQiGuo = ranQiGuoImg.map((img, index) => {
  //   return (
  //     <ProductRanQiGuo
  //       key={index}
  //       img={img}
  //       caption={ranQiGuoCaptions[index]}
  //       text={ranQiGuoTexts[index]}
  //       index={index}
  //       path={ranQiGuoPahs[index]}
  //     />
  //   );
  // });
  const onSwitch = (index) => {
    setState({
      index,
    });
  };
  let productsClass = classes.Products;
  if (state.index === 3) {
    productsClass = classes.ProductsHalf;
  }
  return (
    <div className={classes.Container}>
      <ul className={classes.Lists}>
        <li
          className={state.index === 0 ? classes.Active : null}
          onMouseOver={() => onSwitch(0)}
        >
          发热电缆系列
        </li>
        <li
          className={state.index === 1 ? classes.Active : null}
          onMouseOver={() => onSwitch(1)}
        >
          电伴热系列
        </li>
        <li
          className={state.index === 2 ? classes.Active : null}
          onMouseOver={() => onSwitch(2)}
        >
          空气源系列
        </li>
        <li
          className={state.index === 3 ? classes.Active : null}
          onMouseOver={() => onSwitch(3)}
        >
          燃气锅炉系列
        </li>
      </ul>
      <hr className={classes.BreakLine} />

      <div className={productsClass}>
        {state.index === 0 ? <Product></Product> : null}
        {state.index === 1 ? <ProductDIanBanRe></ProductDIanBanRe> : null}
        {state.index === 2 ? <ProductKongQiYuan></ProductKongQiYuan> : null}
        {state.index === 3 ? <ProductRanQiGuo></ProductRanQiGuo> : null}
      </div>
    </div>
  );
};

export default Products;
