import React from "react";
import List from "./List.js/List";
import classes from "./Lists.module.scss";
const Lists = (props) => {
  return (
    <ul className={classes.Lists}>
      {props.lists
        ? props.lists.map((list, index) => {
            return <List key={index} list={list} />;
          })
        : null}
    </ul>
  );
};

export default Lists;
