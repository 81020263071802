import React from "react";
import KeyWordSearch from "./KeyWordSearch/KeyWordSearch";
import Search from "./Search/Search";
import classes from "./SearchBar.module.scss";
const SearchBar = (props) => {
  return (
    <div className={classes.SeachBar}>
      <KeyWordSearch />
      {/*<Search />*/}
    </div>
  );
};

export default SearchBar;
