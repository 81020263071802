import React from "react";
import classes from "./solutionsPage.module.scss";
import cables from "../../assets/cables.png";
import Layout from "../../hoc/Layout/Layout";
import Jumbotron from "../../components/Jumbotron/Jumbotron";
import { Link } from "react-router-dom";
import 消防电伴热1 from "../../assets/消防电伴热1.jpg";
import 消防电伴热2 from "../../assets/imgs/changjing/4.png";


class solutions extends React.Component {
 constructor(props) {
       super(props)
      }

 render() {
    return (
        <Layout>
            <Jumbotron page="subPage" />
            <main className={classes.Main}>
                <div className={classes.ProductContainer}>
                    <h1>解决方案</h1>
                    {/* to do scroll up */}
                    <a className={classes.Anchor} id="solutions"></a>
                    <ul className={classes.ProductList}>
                        <li className={classes.ProductItem}>
                            <Link to="/solutions/hospital">医院</Link>
                        </li>
                        <li className={classes.ProductItem}>
                            <Link to="/solutions/school">学校</Link>
                        </li>
                        <li className={classes.ProductItem}>
                            <Link to="/solutions/office">机关单位、写字楼</Link>
                        </li>
                        <li className={classes.ProductItem}>
                            <Link to="/solutions/mall">大型商场、会议中心</Link>
                        </li>
                        <li className={classes.ProductItem}>
                            <Link to="/solutions/tunnel">管道电伴热</Link>
                        </li>
                        <li className={classes.ProductItem}>
                            <Link to="/solutions/roof">屋面除雪融冰</Link>
                        </li>
                        <li className={classes.ProductItem}>
                            <Link to="/solutions/road">道路除雪融冰</Link>
                        </li>
                        <li className={classes.ProductItem}>
                            <Link to="/solutions/runway">机场跑道融雪除冰</Link>
                        </li>
                        <li className={classes.ProductItem}  style={{border: "1px #f56a00 solid"}}>
                            <Link to="/solutions/firefighting" style={{color:"#f56a00"}}>消防电伴热</Link>
                        </li>
                        <li className={classes.ProductItem}>
                            <Link to="/solutions/unit">居民住宅</Link>
                        </li>
                    </ul>
                </div>
                <section className={classes.CableSection}>
                    <div className={classes.Header}>
                        <a className={classes.Anchor} id="advanage"></a>
                        <h2>消防电伴热</h2>
                        <hr />
                    </div>

                    <div className={classes.Text}>
                        <img src={消防电伴热1} style={{margin:'0 15%',marginTop:'18px', width: '70%'}}/>

                        <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
                            需求特点：长时间自动管理运行，功耗低，管道距离长。
                        </p>
                        <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
                            解决方案：热源采用瑞海自限温电伴热带配合瑞海智能热控系统和瑞海场景冰雪气象监测技术。

                        </p>
                        <img src={消防电伴热2} style={{margin:'0 15%',marginTop:'18px', width: '70%'}}/>
                        <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
                            消防管道的实时通畅是保卫人民生命和财产的必须。冬天消防管道结冰会导致管道涨裂，引发漏水。而采用管道自限温电伴热带作为热源，就可以及时在天气寒冷时使得水温保持在零度以上不结冰。配合瑞海的互联网计算机集中控制系统及内外温度感应设备，搭配瑞海互联网计算机控制系统和场景冰雪气象监测系统能够精确控制加热设备开关，使伴热带能够根据气象、气候条件自动运行，节省了大量资源。
                        </p>


                    </div>
                </section>
            </main>
        </Layout>
    );
 }
};

export default solutions;