import React, { useState, useEffect, useRef } from "react";
import classes from "./Jumbotron.module.scss";
import monitor from "../../assets/monitor.png";
import ran from "../../assets/Jumbotron/ran.png";
import qi from "../../assets/Jumbotron/qi.png";
import guo from "../../assets/Jumbotron/guo.png";
import lu from "../../assets/Jumbotron/lu.png";
import yi from "../../assets/Jumbotron/yi.png";
import ti from "../../assets/Jumbotron/ti.png";
import hua from "../../assets/Jumbotron/hua.png";
import fu from "../../assets/Jumbotron/fu.png";
import wu from "../../assets/Jumbotron/wu.png";
import headbanner1 from "../../assets/headbanner1.mp4"
import headbanner2 from "../../assets/headbanner2.mp4"
import headbanner3 from "../../assets/headbanner3.mp4"
import headbanner4 from "../../assets/headbanner4.mp4"
import faredlan from '../../assets/productPage/frdlian.gif'
import guolv from '../../assets/rangqi.gif'
import konqiyuan from '../../assets/productPage/konqiyuan.gif'

const Jumbotron = (props) => {
    const [state, setState] = useState({
      index: 0,
      falg: false
  });

  let container = classes.Container;
  if (props.page === "subPage") {
    container = classes.FullContainer;
  }
  const wordArtImg = [ran, qi, guo, lu, yi, ti, hua, fu, wu];
  const wordArtClassName = [
    "Ran",
    "Qi",
    "Guo",
    "Lu",
    "Yi",
    "Ti",
    "Hua",
    "Fu",
    "Wu",
  ];

  const wordArt = wordArtImg.map((img, index) => {
    return (
      <img
        src={img}
        alt={wordArtClassName[index]}
        className={classes[wordArtClassName[index]]}
      />
    );
  });
  let backgroundClass = [
    classes.Background,
    classes.BackgroundSecond,
    classes.BackgroundThird,
    classes.BackgroundFourth,
  ];
  const slideSwitch = (index) => {
    setState({
      index,
    });
  };
  return (
       
    <div className={container}>
      {state.index === 0 ? (
     <a href="/products/features"><video src={headbanner1} width="100%" height="400px"
     x5-playsinline="" playsinline="" webkit-playsinline=""   loop autoPlay muted  preload="auto" ></video></a>
      ) : null}
      {state.index === 1 ? (
     <a href="/products/heat-cable"><video src={headbanner2} width="100%" height="400px"
        x5-playsinline="" playsinline="" webkit-playsinline="" loop autoPlay muted></video></a>
      ) : null}
      {state.index === 2 ? (
     <a href="/products/gas-boiler"><video src={headbanner3} width="100%" height="400px"
        x5-playsinline="" playsinline="" webkit-playsinline=""
      loop autoPlay muted></video></a>
      ) : null}
      {state.index === 3 ? (
     <a href="/products/heat-air"><video src={headbanner4} width="100%" height="400px"
        x5-playsinline="" playsinline="" webkit-playsinline=""
      loop autoPlay muted></video></a>
      ) : null}

      { (window.location.pathname === '/') ? (
          <div className={classes.SlideControl}>
        <span
            className={state.index === 0 ? classes.Active : null}
            onClick={() => slideSwitch(0)}
        >
          1
        </span>
            <span
                className={state.index === 1 ? classes.Active : null}
                onClick={() => slideSwitch(1)}
            >
          2
        </span>
            <span
                className={state.index === 2 ? classes.Active : null}
                onClick={() => slideSwitch(2)}
            >
          3
        </span>
            <span
                className={state.index === 3 ? classes.Active : null}
                onClick={() => slideSwitch(3)}
            >
          4
        </span>
          </div>
      ) : null}
    
    </div>
  );
};
export default Jumbotron;
