import React from "react";
import { Route, BrowserRouter, Redirect, Switch } from "react-router-dom";

class RouterMap extends React.Component {
 constructor(props) {
  super(props)
 }
 render() {
  const { Routes } = this.props;
  const defaultRoute = <Route key={-1} path="/" component={() =>
   <Redirect to="/" />
  } />;
  return <Switch>
   {
    Routes.length && Routes.map((itm, ind) => {
     return <Route key={ind} exact path={itm.path} component={itm.component}></Route>
    }).concat([defaultRoute])
   }
  </Switch>
 }
}
export default RouterMap
