import React from "react";
import classes from "./solutionsPage.module.scss";
import cables from "../../assets/cables.png";
import Layout from "../../hoc/Layout/Layout";
import Jumbotron from "../../components/Jumbotron/Jumbotron";
import { Link } from "react-router-dom";
import 飞机跑道融雪除冰1 from "../../assets/飞机跑道融雪除冰.jpg";

const solutions = () => {
  return (
      <Layout>
        <Jumbotron page="subPage" />
        <main className={classes.Main}>
          <div className={classes.ProductContainer}>
            <h1>解决方案</h1>
            {/* to do scroll up */}
            <a className={classes.Anchor} id="solutions"></a>
            <ul className={classes.ProductList}>
              <li className={classes.ProductItem}>
                <Link to="/solutions/hospital">医院</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/school">学校</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/office">机关单位、写字楼</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/mall">大型商场、会议中心</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/tunnel">管道电伴热</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/roof">屋面除雪融冰</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/road">道路除雪融冰</Link>
              </li>
              <li className={classes.ProductItem} style={{border: "1px #f56a00 solid"}}>
                <Link to="/solutions/runway" style={{color:"#f56a00"}}>机场跑道融雪除冰</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/firefighting">消防电伴热</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/unit">居民住宅</Link>
              </li>
            </ul>
          </div>
          <section className={classes.CableSection}>
            <div className={classes.Header}>
              <a className={classes.Anchor} id="advanage"></a>
              <h2>飞机跑道融雪除冰</h2>
              <hr />
            </div>

            <div className={classes.Text}>
              <img src={飞机跑道融雪除冰1} style={{margin:'0 15%',marginTop:'18px', width: '70%'}}/>

              <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
                需求特点：耐腐蚀、承压高、抗变形过载大、使用寿命长、根据气象气候条件自动运行。
              </p>
              <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
                解决方式：热源采用机场跑道融雪发热电缆配合瑞海智能热控系统、瑞海机场跑道冰雪监测技术、瑞海场景气象探测技术。

              </p>

              <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
                瑞海机场跑道融雪方案中。发热电缆采用特殊工艺的外壳，提高了机械强度可承受瞬时间超高的路面压力、也有很强的导热性和耐腐蚀性。此外使用双导结构，不但易于安装，而且易于更换。现有的措施是简单的人工和铲雪机物理清除和铺撒融雪盐，效率低下，人员物资浪费严重。对于连续降、大雪量降雪无法应对。而融雪剂对昂贵的机场跑道有很强的腐蚀性，大大减少使用寿命。采用机场跑道融雪发热电缆搭配瑞海互联网计算机控制系统、瑞海机场跑道冰雪监测系统和瑞海场景气象探测系统，能够根据气温、湿度、跑道温度、发热电缆温度等情况等多项数据综合反馈，一目了然体现在前端显示界面上，然后根据设定值自动运行调整加热温度及频率。做到提前预警、提早预热、自动开闭的功能。有效节省了人力物力，大大提高了跑道的使用效率。
              </p>


            </div>
          </section>
        </main>
      </Layout>
  );
};

export default solutions;