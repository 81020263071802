import productPagefeatures from "../pages/productPage/productPagefeatures";
import aboutPage from "../pages/aboutPage/aboutPage";
import processPage from "../pages/processPage/processPage";
import contactUsPage from "../pages/contactUsPage/contactUsPage";
import productPagedianrelan from "../pages/productPage/productPagedianrelan";
import productPagedianbanre from "../pages/productPage/productPagedianbanre";
import productPagekongqiyuanrebeng from "../pages/productPage/productPagekongqiyuanrebeng";
import productPageranqiguolu from "../pages/productPage/productPageranqiguolu";
import solutionsPageSchool from "../pages/solutionsPage/solutionsPageSchool";
import solutionsPageHospital from "../pages/solutionsPage/solutionsPageHospital";
import solutionsPageUnit from "../pages/solutionsPage/solutionsPageUnit";
import solutionsPageMall from "../pages/solutionsPage/solutionsPageMall";
import solutionsPageRoad from "../pages/solutionsPage/solutionsPageRoad";
import solutionsPageRunway from "../pages/solutionsPage/solutionsPageRunway";
import solutionsPageRoof from "../pages/solutionsPage/solutionsPageRoof";
import solutionsPageTunnel from "../pages/solutionsPage/solutionsPageTunnel";
import solutionsPageFirefighting from "../pages/solutionsPage/solutionsPageFirefighting";
import solutionsPageOffice from "../pages/solutionsPage/solutionsPageOffice";
import casesPage from "../pages/casesPage/casesPage";
import news from '../pages/news/news'
import Main from "../components/Main/Main";
import sourceanimation from '../pages/sourceanimation/sourceanimation'
const Routes = [
 {
  path: "/",
  component: Main
 },
 {
  path: "/sourceanimation",
  component: sourceanimation
 },
 {
  path: "/news",
  component: news
 },
 {
  path: "/aboutUs",
  component: aboutPage
 },
 {
  path: "/process",
  component: processPage
 },
 {
  path: "/contactUs",
  component: contactUsPage
 },
 {
  path: "/cases",
  component: casesPage
 },
 {
  path: "/products/features",
  // exact: exact,
  component: productPagefeatures
 },
 {
  path: "/products/heat-cable",
  component: productPagedianrelan
 },
 {
  path: "/products/heat-power",
  component: productPagedianbanre
 },
 {
  path: "/products/heat-air",
  component: productPagekongqiyuanrebeng
 },
 {
  path: "/products/gas-boiler",
  component: productPageranqiguolu
 },
 {
  path: "/solutions/school",
  component: solutionsPageSchool
 },
 {
  path: "/solutions/unit",
  component: solutionsPageUnit
 },
 {
  path: "/solutions/hospital",
  component: solutionsPageHospital
 },
 {
  path: "/solutions/mall",
  component: solutionsPageMall
 },
 {
  path: "/solutions/road",
  component: solutionsPageRoad
 },
 {
  path: "/solutions/runway",
  component: solutionsPageRunway
 },
 {
  path: "/solutions/roof",
  component: solutionsPageRoof
 },
 {
  path: "/solutions/tunnel",
  component: solutionsPageTunnel
 },
 {
  path: "/solutions/firefighting",
  component: solutionsPageFirefighting
 },
 {
  path: "/solutions/office",
  component: solutionsPageOffice
 }


]

export default Routes;