import React from "react";
import classes from "./Cases.module.scss";
import school from "../../../../assets/school.png";
import { HashLink as Link } from "react-router-hash-link";
const Cases = (props) => {
  return (
    <div className={classes.Cases}>
      <h3>精彩案例</h3>
      <div className={classes.ImgBox}>
        <img loading="lazy" src={school} alt="#" />
      </div>
      <div className={`${classes.TextBox1} ${classes.TextBox}`}>
        <Link to="/cases/#project">国家电网太原分公司高层宿舍集中供暖项目</Link>
     <p>
      
          单体铺设面积达2万平米，采用电采暖集中控制系统， 铺设6年，运营良好...
        </p>
      </div>
      <div className={`${classes.TextBox2} ${classes.TextBox}`}>
        <Link to="/cases/#mode">北京假日阳光别墅供暖升级项目</Link>
        <p>
          位于北京市延庆区，建筑类型为连体平层别墅。别墅坐
          落在八达岭长城附近，昼夜温差大...
        </p>
      </div>
      <div className={`${classes.TextBox3} ${classes.TextBox}`}>
        <Link to="/cases/#research">山西忻州同煤大厦智能化供暖项目</Link>
        <p>
          忻州同煤大厦是集办公、会议于一体的综合办公楼，采
          用电采暖集中控制系统控制...
        </p>
      </div>
      <div className={`${classes.TextBox4} ${classes.TextBox}`}>
        <Link to="/cases/#solution">太原学院新校区综合供暖项目</Link>
        <p>
          自2011年新校区建成使用至今，集中控制电采暖建筑面积约
          20万平方米。包含教学楼、学生公寓楼、食堂等...
        </p>
      </div>
      <div className={`${classes.TextBox5} ${classes.TextBox}`}>
        <Link to="/cases/#advanage">沈阳新世纪家园别墅智能化供暖项目</Link>
        <p>
          业主居住房间和时间长度不一：许多业主表示希望能自
          己调节房间温度和开关状态来节省供暖费...
        </p>
      </div>
    </div>
  );
};

export default Cases;
