import React from "react";
import classes from "./Logo.module.scss";
import logo from "../../assets/logo.png";
const Logo = (props) => (
  <div className={classes.Logo}>
    <img src={logo} alt="瑞海网页商标" />
  </div>
);

export default Logo;
