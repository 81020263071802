import React from "react";
import classes from "./ProductDianBanRe.module.scss";
import fare from '../../../../../../src/assets/imgs/dbanre.jpg'
const Product = (props) => {
  return (
    <div className={classes.Product}>
    <img src={fare} loading="lazy"></img>
    <p className={classes.fare}>
       <b>电伴热</b>
   <span>瑞海电伴热防冻及保温系统采用自限温电热带或恒功率电热带为加热元件。采用国内最先进的PTC高分子材料组方,结构上增加电阻过渡层,工艺采用双芯双层超薄共挤技术。对需要防冻及保温的各式设备进行各种温度需求的伴热或加热,补充流体损失掉的热量，使温度保持在允许的范围内。</span></p>
    </div>
  );
};

export default Product;
