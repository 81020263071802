import React from "react";
import classes from "./ProductSection.module.scss";
import SideBar from "./SideBar/SideBar";
import Products from "./Products/Products";
const ProductSection = (props) => {
  return (
    <section className={classes.ProductSection}>
      <SideBar />
      <Products />
    </section>
  );
};

export default ProductSection;
