import React from "react";
import classes from "./Legal.module.scss";
import legal from "../../assets/legal.png";
const Legal = (props) => {
  return (
    <div className={classes.Legal}>
      <img className={classes.Icon} src={legal} alt="" />
      <p>晋公网安备 14019202000612号</p>
    </div>
  );
};

export default Legal;
