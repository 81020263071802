import React from "react";
import classes from "./ProductKongQiYuan.module.scss";
import konqiyuan1 from '../../../../../../src/assets/imgs/kqyuan.jpg'
import konqiyuan2 from '../../../../../../src/assets/imgs/konqiyuan2.jpg'
import konqiyuan3 from '../../../../../../src/assets/imgs/konqyuan3.jpg'
const Product = (props) => {
  const productClass = [
    classes.Product,
    classes.ProductSecond,
    classes.ProductThird,
  ];

  return (
   <div className={classes.wrap}>
     <div className={classes.Productkonqyuan}>
     <p><img src={konqiyuan1} loading="lazy"></img> <span>螺杆式超低环温机组</span></p>
      <div className={classes.title_names}><span><img src={konqiyuan2} loading="lazy"></img><b>多功能串联机组</b></span>
     <span><img className={classes.konqiyuan3} src={konqiyuan3} loading="lazy"></img><b>多功能串联机组</b></span></div>
    </div>
     
    <p className={classes.text}>  <span className={classes.title}>空气源</span><span className={classes.title2}>瑞海热控的空气源热泵系统针对市场需求和智能化进步，不断革新升级，采用创新双级压缩机和喷气增焓技术，大幅提升了机组整体性能。实现了一台气源热泵机组，即可提供冷/热双供空调、地暖、热水供应、恒温储藏等多功能一体化服务。</span></p>
    </div>
   
  );
};

export default Product;
