import React from "react";
import classes from "./ProductRanQiGuo.module.scss";
import fare from '../../../../../../src/assets/imgs/reqi.jpg'
const ProductRanQiGuo = (props) => {
  return (
   <div className={classes.Product}>
    <img src={fare} loading="lazy"></img>
    <p className={classes.fare}>
       <b>燃气锅炉</b>
   <span>燃气锅炉系统解决方案采用全预混冷凝锅炉，冷凝燃气锅可充分回收烟气中的显热和地凝结潜热，热效率将大幅提高，满足现在环保最严标准的锅炉，具有高效节能、清洁环保两大优势。</span></p>
    </div>
  );
};

export default ProductRanQiGuo;
