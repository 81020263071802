import React, { useState } from "react";
import classes from "./NavItem.module.scss";
import { Link } from "react-router-dom";
import Dropdowns from "../../Dropdowns/Dropdowns";

const NavItem = (props) => {
  const [toggleState, setToggleState] = useState({
    toggled: false,
  });
  const onToggleOn = () => {
    setToggleState({
      toggled: true,
    });
  };
  const onToggleOff = () => {
    setToggleState({
      toggled: false,
    });
  };
  return (
    <li
      className={classes.NavItem}
      onMouseOver={onToggleOn}
      onMouseLeave={onToggleOff}
    >
      {props.dropdownMenu && toggleState.toggled ? (
          <div>
            <a>{props.children}</a>
            <Dropdowns
                dropdownMenu={props.dropdownMenu}
                dropdownMenuPaths={props.dropdownMenuPaths}
            />
          </div>

      ) : (<Link to={props.path}>{props.children}</Link>)}
    </li>
  );
};

export default NavItem;
