import React from "react";
import classes from "./solutionsPage.module.scss";
import cables from "../../assets/cables.png";
import Layout from "../../hoc/Layout/Layout";
import Jumbotron from "../../components/Jumbotron/Jumbotron";
import { Link } from "react-router-dom";
import 公路隧道桥梁融雪除冰1 from "../../assets/公路隧道桥梁融雪除冰1.jpg";
import 公路隧道桥梁融雪除冰2 from "../../assets/imgs/changjing/1.jpg";

const solutions = () => {
  return (
      <Layout>
        <Jumbotron page="subPage" />
        <main className={classes.Main}>
          <div className={classes.ProductContainer}>
            <h1>解决方案</h1>
            {/* to do scroll up */}
            <a className={classes.Anchor} id="solutions"></a>
            <ul className={classes.ProductList}>
              <li className={classes.ProductItem}>
                <Link to="/solutions/hospital">医院</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/school">学校</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/office">机关单位、写字楼</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/mall">大型商场、会议中心</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/tunnel">管道电伴热</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/roof">屋面除雪融冰</Link>
              </li>
              <li className={classes.ProductItem} style={{border: "1px #f56a00 solid"}}>
                <Link to="/solutions/road" style={{color:"#f56a00"}}>道路除雪融冰</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/runway">机场跑道融雪除冰</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/firefighting">消防电伴热</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/unit">居民住宅</Link>
              </li>
            </ul>
          </div>
          <section className={classes.CableSection}>
            <div className={classes.Header}>
              <a className={classes.Anchor} id="advanage"></a>
              <h2>公路、隧道、桥梁融雪除冰</h2>
              <hr />
            </div>

            <div className={classes.Text}>
              <img src={公路隧道桥梁融雪除冰1} style={{margin:'0 15%',marginTop:'18px', width: '70%'}}/>

              <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
                需求特点：耐腐蚀、承压高、抗变形过载大、使用寿命长、根据气象气候条件自动运行。
              </p>
              <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
                解决方案：热源采用道路融雪恒功电伴热带配合瑞海智能热控系统和瑞海路面冰雪监测技术。。

              </p>
              <img src={公路隧道桥梁融雪除冰2} style={{margin:'0 15%',marginTop:'18px', width: '70%'}}/>

              <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
                随着我国交通事业的快速发展，自动化的道路、隧道、桥梁融雪需求也被提上日程。现有的措施是简单的人工物理清除和铺撒融雪盐，效率低下，人员物资浪费严重。而融雪剂对路面有很强的腐蚀性，大大降低路面使用寿命。采用融雪恒功电伴热带搭配瑞海互联网计算机控制系统和瑞海路面冰雪监测系统，能够根据气温、湿度、路面冰雪情况等多项数据综合反馈，一目了然体现在前端显示界面上，控制系统可以根据设定值，参照各种运行数据自动运行调整加热温度及频率。做到提前预警、提早预热、自动开闭的功能。大大节省了人力物力，有效提高了道路、隧道、桥梁的使用效率。
              </p>


            </div>
          </section>
        </main>
      </Layout>
  );
};

export default solutions;