import React from "react";
import classes from "./solutionsPage.module.scss";
import cables from "../../assets/cables.png";
import Layout from "../../hoc/Layout/Layout";
import Jumbotron from "../../components/Jumbotron/Jumbotron";
import { Link } from "react-router-dom";
import 屋顶融雪除冰1 from "../../assets/imgs/changjing/3.jpg";
import 屋顶融雪除冰2 from "../../assets/屋顶融雪除冰2.jpg";

const solutions = () => {
  return (
      <Layout>
        <Jumbotron page="subPage" />
        <main className={classes.Main}>
          <div className={classes.ProductContainer}>
            <h1>解决方案</h1>
            {/* to do scroll up */}
            <a className={classes.Anchor} id="solutions"></a>
            <ul className={classes.ProductList}>
              <li className={classes.ProductItem}>
                <Link to="/solutions/hospital">医院</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/school">学校</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/office">机关单位、写字楼</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/mall">大型商场、会议中心</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/tunnel">管道电伴热</Link>
              </li>
              <li className={classes.ProductItem} style={{border: "1px #f56a00 solid"}}>
                <Link to="/solutions/roof" style={{color:"#f56a00"}}>屋面除雪融冰</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/road">道路除雪融冰</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/runway">机场跑道融雪除冰</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/firefighting">消防电伴热</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/unit">居民住宅</Link>
              </li>
            </ul>
          </div>
          <section className={classes.CableSection}>
            <div className={classes.Header}>
              <a className={classes.Anchor} id="advanage"></a>
              <h2>屋顶融雪除冰</h2>
              <hr />
            </div>

            <div className={classes.Text}>
              <img src={屋顶融雪除冰1} style={{margin:'0 15%',marginTop:'18px', width: '70%'}}/>

              <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
                需求特点：根据综合情况自动运行、耐腐蚀、抗老化、加热速度快。
              </p>
              <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
                解决方案：热源采用融雪自限温电伴热带或恒功电伴热带配合瑞海智能热控系统和瑞海场景冰雪气象监测技术。

              </p>
              <img src={屋顶融雪除冰2} style={{margin:'0 15%',marginTop:'18px', width: '70%'}}/>

              <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
                屋顶融雪自限温电伴热或恒功电伴热带带现阶段大量应用于各类大开间、长距离钢混结构轻质面（如体育场馆、候机楼、车站楼），能快速融雪除冰，降低建筑物屋顶承压负荷，提高建筑安全性和使用寿命。搭配瑞海互联网计算机控制系统和场景冰雪气象监测系统能够精确控制加热设备开闭，使伴热带能够根据气象、气候条件自动运行，节省了大量资源。
              </p>


            </div>
          </section>
        </main>
      </Layout>
  );
};

export default solutions;