import React from "react";
import NavItems from "../NavItems/NavItems";
import classes from "./NavBar.module.scss";
import Logo from "../../Logo/Logo";
import Language from "../../Language/Language";
class NavBar extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
      return (
    <div className={classes.NavBar}  >
      <div className={classes.Container}>
        <Logo />
        {/*<Language />*/}
      </div>
      <nav className={classes.Nav} >
        <NavItems />
      </nav>
    </div>
  );
   }
};

export default NavBar;
