import React from "react";
import classes from "./Arrows.module.scss";
import Arrow from "./Arrow/Arrow";
const Arrows = (props) => {
  return (
    <div className={classes.Arrows}>
      <Arrow type="Arrow-left" order="Arrow-1">
        需求探索
      </Arrow>
      <Arrow order="Arrow-2">前期准备</Arrow>
      <Arrow order="Arrow-3">制定项目目标</Arrow>
      <Arrow order="Arrow-4">制定解决方案</Arrow>
      <Arrow order="Arrow-5">项目建设</Arrow>
      <Arrow order="Arrow-6">项目运营</Arrow>
    </div>
  );
};

export default Arrows;
