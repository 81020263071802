import React from "react";
import Layout from "../../hoc/Layout/Layout";
import classes from "./contactUsPage.module.scss";
import Jumbotron from "../../components/Jumbotron/Jumbotron";
import wubingCard from "../../assets/contactUs/wubing.jpg"
import wangtaoweiCard from "../../assets/contactUs/wantaowei.jpg"

const contactUsPage = () => {
 return (
    <Layout>
      <Jumbotron page="subPage" />
      <main className={classes.Main}>
        <div className={classes.ContactUsContainer}>
          <h1>联系瑞海</h1>
          <div className={classes.Container}>
            <h3>山西瑞海节能网控电热集中供暖有限公司</h3>
            <p>
              联系邮箱：
              <a className={classes.Email}>qinyang@rhthermo.com</a>
            </p>
            <p>
              联系电话：
              0351-2334100
            </p>
            <br/>
         <img src={wubingCard} loading="lazy" style={{ margin: '18px 15%' }} width="100%" alt="" />
         <img src={wangtaoweiCard} loading="lazy" style={{margin:'18px 15%'}} width="100%" alt=""/>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default contactUsPage;
