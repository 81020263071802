import React, { Component } from "react";
import classes from "./Dropdown.module.scss";
import { Link } from "react-router-dom";
class Dropdown extends Component {
  state = {
    toggled: false,
  };

  onToggle = () => {
    this.setState((prevState) => {
      return { toggled: !prevState.toggled };
    });
  };

  render() {
    let toggleClass = [classes.Toggle];

    if (this.state.toggled) {
      toggleClass.push(classes.Toggled);
    }

    let items = this.props.items.map((item, index) => {
      return (
        <Link to={this.props.paths[index]} className={classes.Item} key={item}>
          <span className={classes.MidDot}>&middot;</span>
          {item}
        </Link>
      );
    });
    return (
      <div className={classes.Dropdown}>
        {this.props.menuName ? (
          <div className={toggleClass.join(" ")} onClick={this.onToggle}>
            <span className={classes.MidDot}>&middot;</span>
            {this.props.menuName}
          </div>
        ) : (
          <ul className={classes.Items}>{items}</ul>
        )}

        {this.state.toggled && this.props.menuName ? (
          <ul className={classes.Menu}>{items}</ul>
        ) : null}
      </div>
    );
  }
}

export default Dropdown;
