import React from "react";
import Layout from "../../hoc/Layout/Layout";
import classes from "./news.module.scss";
import Jumbotron from "../../components/Jumbotron/Jumbotron";
import handpage from "../../assets/Jumbotron/kaocha.jpg";
import data from '../../common/news_list.json'

 class news extends React.Component  {
   constructor(props) {
      super(props)
   }
   get_rhthermo_news() {
     window.open('https://mp.weixin.qq.com/mp/homepage?__biz=MzI5MDk5MDc2Mg==&hid=4&sn=d31bb0148ebbbee532db9da35bbef258&scene=18&uin=&key=&devicetype=Windows+10+x64&version=6302017f&lang=zh_CN&ascene=7&fontgear=2')
   }
   news_hot() {
     window.open('https://mp.weixin.qq.com/mp/homepage?__biz=MzI5MDk5MDc2Mg==&hid=11&sn=b66b896e5659ec439a69e81a59b1102b&scene=18&uin=&key=&devicetype=Windows+10+x64&version=6302017f&lang=zh_CN&ascene=7&fontgear=2')
   }
   news_hot_more() {
     window.open('https://mp.weixin.qq.com/mp/homepage?__biz=MzI5MDk5MDc2Mg==&hid=13&sn=1dbdefb96a920eeddedd3f8b6d207fe6&scene=18&uin=&key=&devicetype=Windows+10+x64&version=6302017f&lang=zh_CN&ascene=7&fontgear=2')
   }
   render() {
      return (
       <Layout>
      <Jumbotron page="subPage" />
      <main className={classes.Main}>
        <div className={classes.AboutContainer}>
          <h1>资讯中心</h1>
        </div>
        <section className={classes.GallerySection}>
        <div className={classes.Header}>
            <h2>{data.news.title}</h2>
            <hr />
          </div>
          <div className={classes.news_list}>
            {data.news.news_text.news_text_left.length && data.news.news_text.news_text_left.map((val, index) => {
              return <div key={index}  className={classes.news_list_left}>
                <h4 className={classes.title_text}>
                 <span className={classes.title_usertext_name}
                 onClick={() => {
                  window.open(val.link)
                }}
                >{val.name}</span>
                <span className={classes.date_name}
                  onClick={() => {
                window.open(val.link)
                }}
                >{ val.date}</span>

                </h4>
                <img src={handpage} loading="lazy" className={ classes.news_img}  onClick={() => {
                window.open(val.link)
                }}/>
                <p   onClick={() => {
                window.open(val.link)
                  
                }}  className={classes.title_usertext} id={classes.text_coller}>
                {val.text}
              </p>
              </div>
            })}
               <div className={classes.news_list_right}>
                    {
              data.news.news_text.news_text_right.length && data.news.news_text.news_text_right.map((val, index) => {
                return  <div  key={index}>
                 <h4 className={classes.title_text_right} >
                <span   className={classes.title_usertext_name_right}   onClick={() => {
                window.open(val.link)
                
              }} >{val.name}</span>
                  <span className={classes.date}
                   onClick={() => {
              window.open(val.link)
              }} 
                    >{ val.date}</span>
              </h4>
                <p id={classes.text_coller}
                  onClick={() => {
                window.open(val.link)
                }} 
                >
               {val.text}
                </p>
                </div>
             })
             }
               </div>
          </div>
          <p className={classes.new_more} onClick={() => {
            this.get_rhthermo_news()
            }}>更多...</p>
        </section>
           <div className={classes.news_footer}>
        <section className={classes.GallerySection_news_science}>
          <div className={classes.newshrHeader}>
              <h2>{ data.Industry.title}</h2>
            <hr className={classes.newshr}/>
            </div>
            {
              data.Industry.Industry_text.length && data.Industry.Industry_text.map((val, index) => {
               return  <div  className={classes.box} key={index}>
                <h4 className={classes.title_hotspot}>
                   <span
                     className={classes.title_usertext_name}
                  onClick={() => {
                  window.open(val.link)    
                 }}
                   >{val.name}</span>
                <span className={classes.date}
                   onClick={() => {
                 window.open(val.link)    
                 }}
                   >{val.date}</span>
              </h4>
               <p
                  onClick={() => {
                 window.open(val.link)    
                 }}
                className={classes.title_hot_text} id={classes.text_coller}>
                {val.text}
                </p>
            </div>

             })  
            }
                <p className={classes.new_hot_more} onClick={() => {
                  this.news_hot()
              }}>更多...</p>
          </section>
          
        <section className={classes.GallerySection_news_science}>
          <div className={classes.newshrHeader}>
              <h2>{ data.metro.title}</h2>
            <hr className={classes.newshr}/>
            </div>
            {
              data.metro.metro_text.length && data.metro.metro_text.map((val, index) => {
               return  <div  key={index} className={classes.box}>
                <h4 className={classes.title_hotspot}>
                   <span
                     className={classes.title_usertext_name}
                  onClick={() => {
                  window.open(val.link)    
                 }}
                   >{val.name}</span>
                <span className={classes.date}
                   onClick={() => {
                 window.open(val.link)    
                 }}
                   >{val.date}</span>
              </h4>
               <p
                  onClick={() => {
                 window.open(val.link)    
                 }}
                className={classes.title_hot_text} id={classes.text_coller}>
                {val.text}
                </p>
            </div>

             })  
            }
                <p className={classes.new_hot_more2} onClick={() => {
                  this.news_hot_more()
         }}>更多...</p>
                </section>
                </div>
            </main>
    </Layout>
  );
   }
};



export default news;