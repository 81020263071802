import React from "react";
import classes from "./NavItems.module.scss";
import NavItem from "./NavItem/NavItem";
const NavItems = (props) => {
  const solutionMenus = [
    [
      "大型项目整体方案",
      "校园",
      "机关单位、写字楼",
      "医院",
      "大型商场、会议中心",
    ],
    ["除雪融冰方案", "道路、隧道、桥梁", "机场跑道", "屋面"],
    ["电伴热方案", "管道电伴热", "消防电伴热"],
  ];
  const solutionPaths = [
    [
      "/solutions/school",
      "/solutions/office",
      "/solutions/hospital",
      "/solutions/mall",
    ],
    ["/solutions/road", "/solutions/runway", "/solutions/roof"],
    ["/solutions/tunnel", "/solutions/firefighting"],
  ];
  const productMenus = [
    "瑞海智慧能源云平台",
    "发热电缆一体化服务",
    "电伴热一体化服务",
    "空气源热泵一体化服务",
    "燃气锅炉一体化服务",
  ];

  const productPaths = [
    "/products/features",
    "/products/heat-cable",
    "/products/heat-power",
    "/products/heat-air",
    "/products/gas-boiler",
  ];

  return (
    <ul className={classes.NavItems}>
      <NavItem path="/">瑞海首页</NavItem>

      <NavItem path="/aboutUs">关于瑞海</NavItem>
      <NavItem
        dropdownMenu={productMenus}
        dropdownMenuPaths={productPaths}
      >
        整体服务
      </NavItem>

      <NavItem
        dropdownMenu={solutionMenus}
        dropdownMenuPaths={solutionPaths}
      >
        应用场景
      </NavItem>

      <NavItem path="/process">服务流程</NavItem>
      <NavItem path="/news">新闻资讯</NavItem>
      <NavItem path="/cases">精彩案例</NavItem>
       

      <NavItem path="/contactUs">联系瑞海</NavItem>
    </ul>
  );
};

export default NavItems;
