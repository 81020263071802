import React from "react";
import classes from "./SideBar.module.scss";
import { Link } from "react-router-dom";
const SideBar = (props) => {
  return (
    <div className={classes.SideBarBack}>
      <h3 className={classes.Title}>核心产品应用场景</h3>
      <div className={classes.SideBarFront}>
        <div className={classes.Lists}>
          <h3>
          大型项目整体方案
          </h3>
          <ul>
            <li>
              <Link to="/solutions/school">校园综合供热</Link>
            </li>
            <li>
              <Link to="/solutions/office">机关单位、写字楼供热</Link>
            </li>
            <li>
              <Link to="/solutions/hospital">医院综合供热</Link>
          </li>
            <li>
              <Link to="/solutions/mall">大型商场、会议中心</Link>
            </li>
          </ul>
        </div>
        <div className={classes.Lists}>
          <h3>
          除雪融冰方案
          </h3>
          <ul>
            <li>
              <Link to="/solutions/road">道路、隧道、桥梁</Link>
            </li>
            <li>
                 <Link to="/solutions/runway">机场跑道</Link>
          </li>
           <li>
              <Link to="/solutions/roof">屋顶融雪除冰</Link>
            </li>
          </ul>
        </div>
        <div className={classes.Lists}>
          <h3>
          电伴热方案
          </h3>
          <ul>
            <li>
              <Link to="/solutions/tunnel">管道电伴</Link>
            </li>
            <li>
              <Link to="/solutions/firefighting">消防电伴热</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
