import React from 'react';
import { getData } from "../../common/MachineData";
import classes from "./sourceanimation.module.scss";

class sourceanimation extends React.Component{
 constructor(props) {
  super(props)
   this.initindex= [];
    this.state = {
      parts: getData(),
      falg:false,
      "threshold_value":500,
      refNT:[],
      status: false
    }
 }
     handleTransitionEnd() {
      const last_elem_index = 12;
      let elements = [];
      for (let elem in this.refs) {  
        elements.push(this.refs[elem]);
      }
      elements[last_elem_index].addEventListener('transitionend', () => {
        setTimeout( () => {
          window.removeEventListener('mousewheel', this.handlePreventDefault, false);
          window.addEventListener('mousewheel',this.handleMouseWheel, { passive: true });
       
        }, 200);
      })
   }
     handlePreventDefault(event) {
      event.preventDefault();
    }


  componentDidMount(){
    const body = document.getElementsByTagName('body')[0];
    body.style.height = "100%";
    this.handleTransitionEnd()
    this.handleMouseWheel.bind(this);
    window.addEventListener('mousewheel', this.handleMouseWheel, { passive: true });
  }
  handleMove(delta) {
   window.addEventListener('mousewheel', this.handlePreventDefault, { passive: false })
   let attr_name = '';
   if (delta < 0) {
     attr_name = 'end_state';
     this.setState({
       status: true
     })

   } else {
     attr_name = 'init_state';
     this.setState({
       status: false
     })
   }
   let  parts = this.state.parts.map( item => {
     if (!item[attr_name]) {
       return item;
     }
     item.state = item[attr_name]
     return item;
   });
   this.setState({
    parts:parts
   })
}
  handleMouseWheel = event => {
    let delta = 0;
    let threshold_value = this.state.threshold_value
    if (!event) event = window.event;
    if (event.wheelDelta) {
      delta = event.wheelDelta;
      if (window.opera) {
        delta = -delta;
      }
    } else if (event.detail) {
      delta = -event.detail;
    }
    const offset_top = this.refs.container.getBoundingClientRect().top;
    console.log("offset top: ", offset_top, "state: ", this.state.status);
    if (delta < 0 && this.state.status === false && offset_top <= threshold_value) {
      this.handleMove(delta);
    } else if (delta > 0 && offset_top <= threshold_value && this.state.status === true) {
      this.handleMove(delta);
    }
  }
  componentWillUnmount() {
   const body = document.getElementsByTagName('body')[0];
   body.style.height = "1000px";
    window.removeEventListener('mousewheel', this.handlePreventDefault);
    window.removeEventListener('mousewheel', this.handleMouseWheel)
  }
 
 render() {
   const {parts}=this.state
  return <div className={classes.wrap}>
    <div className={classes.container} ref='container'>
  {parts.length && parts.map((val, index) => {
   this.initindex[index] = React.createRef();
   return <div  ref={'part'+index} key={index} className={classes.sparepartsbase} style={{backgroundImage:`url(${val.image})`,backgroundPosition:`${val.state.x_state}px  ${val.state.y_state}px` }}></div>
      })}
     </div>
     <div className={classes.nexts}>
     </div>
  </div>
  
 }
}

export default sourceanimation;
