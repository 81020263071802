import React from "react";
import classes from "./solutionsPage.module.scss";
import cables from "../../assets/cables.png";
import Layout from "../../hoc/Layout/Layout";
import Jumbotron from "../../components/Jumbotron/Jumbotron";
import { Link } from "react-router-dom";
import 办公楼楼宇智能化管理1 from "../../assets/办公楼楼宇智能化管理1.jpg";
import 办公楼楼宇智能化管理2 from "../../assets/办公楼楼宇智能化管理2.jpg";
import 办公楼楼宇智能化管理3 from "../../assets/办公楼楼宇智能化管理3.jpg";

const solutions = () => {
    return (
        <Layout>
            <Jumbotron page="subPage" />
            <main className={classes.Main}>
                <div className={classes.ProductContainer}>
                    <h1>解决方案</h1>
                    {/* to do scroll up */}
                    <a className={classes.Anchor} id="solutions"></a>
                    <ul className={classes.ProductList}>
                        <li className={classes.ProductItem}>
                            <Link to="/solutions/hospital">医院</Link>
                        </li>
                        <li className={classes.ProductItem}>
                            <Link to="/solutions/school">学校</Link>
                        </li>
                        <li className={classes.ProductItem} style={{border: "1px #f56a00 solid"}}>
                            <Link to="/solutions/office" style={{color:"#f56a00"}}>机关单位、写字楼</Link>
                        </li>
                        <li className={classes.ProductItem}>
                            <Link to="/solutions/mall">大型商场、会议中心</Link>
                        </li>
                        <li className={classes.ProductItem}>
                            <Link to="/solutions/tunnel">管道电伴热</Link>
                        </li>
                        <li className={classes.ProductItem}>
                            <Link to="/solutions/roof">屋面除雪融冰</Link>
                        </li>
                        <li className={classes.ProductItem}>
                            <Link to="/solutions/road">道路除雪融冰</Link>
                        </li>
                        <li className={classes.ProductItem}>
                            <Link to="/solutions/runway">机场跑道融雪除冰</Link>
                        </li>
                        <li className={classes.ProductItem}>
                            <Link to="/solutions/firefighting">消防电伴热</Link>
                        </li>
                        <li className={classes.ProductItem}>
                            <Link to="/solutions/unit">居民住宅</Link>
                        </li>
                    </ul>
                </div>
                <section className={classes.CableSection}>
                    <div className={classes.Header}>
                        <a className={classes.Anchor} id="advanage"></a>
                        <h2>办公楼楼宇智能化管理</h2>
                        <hr />
                    </div>

                    <div className={classes.Text}>
                        <img src={办公楼楼宇智能化管理1} style={{margin:'0 15%',marginTop:'18px', width: '70%'}}/>

                        <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
                            需求特点：温控精确、冷热双供、自动化运行、较高的舒适性需求和运行的稳定。
                        </p>
                        <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
                            解决方案：热源采用瑞海发热电缆、空气源热泵配合瑞海智能热控系统的建筑集群控制系统。
                        </p>

                        <img src={办公楼楼宇智能化管理2} style={{margin:'0 15%',marginTop:'18px', width: '70%'}}/>

                        <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
                            采用瑞海发热电缆与瑞海空气源热泵相结合的多种热源方式。配合瑞海热控的互联网计算机集中控制系统，能精确控温，根据每个用暖点位要求不同随时改变供暖温度及时间。空气源热泵机组联网控制，解决夏季供冷冬季供暖的需求。通过瑞海热控建筑集群控制系统调控实现变频供能、联动节能的新型能源调控手段，有效降低成本，提高效能，延长设备寿命。
                        </p>

                        <img src={办公楼楼宇智能化管理3} style={{margin:'0 15%',marginTop:'18px', width: '70%'}}/>

                        <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
                            瑞海热控的互联网计算机集中控制系统在舒适性上能给用户带来极大的享受，根据各个房间的温度、湿度，计算凝露点，调节水温和设备功率，使得风机盘管吹出来的风更加接近人体舒适温度，也不会降低房间湿度。让人不会得空调病。在运行稳定方面，瑞海集中控制系统根据设定值及实施数据自动运行、自动存储运行数据、实时显示当前运行情况，实现自动运行、故障报警、数据记录，保障系统的稳定安全工作。
                        </p>

                    </div>
                </section>
            </main>
        </Layout>
    );
};

export default solutions;