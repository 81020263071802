import React from "react";
import classes from "./solutionsPage.module.scss";
import cables from "../../assets/cables.png";
import Layout from "../../hoc/Layout/Layout";
import Jumbotron from "../../components/Jumbotron/Jumbotron";
import { Link } from "react-router-dom";
import 管道电伴热1 from "../../assets/管道电伴热1.jpg";
import 管道电伴热2 from "../../assets/管道电伴热2.jpg";


const solutions = () => {
    return (
        <Layout>
            <Jumbotron page="subPage" />
            <main className={classes.Main}>
                <div className={classes.ProductContainer}>
                    <h1>解决方案</h1>
                    {/* to do scroll up */}
                    <a className={classes.Anchor} id="solutions"></a>
                    <ul className={classes.ProductList}>
                        <li className={classes.ProductItem}>
                            <Link to="/solutions/hospital">医院</Link>
                        </li>
                        <li className={classes.ProductItem}>
                            <Link to="/solutions/school">学校</Link>
                        </li>
                        <li className={classes.ProductItem}>
                            <Link to="/solutions/office">机关单位、写字楼</Link>
                        </li>
                        <li className={classes.ProductItem}>
                            <Link to="/solutions/mall">大型商场、会议中心</Link>
                        </li>
                        <li className={classes.ProductItem} style={{border: "1px #f56a00 solid"}}>
                            <Link to="/solutions/tunnel"  style={{color:"#f56a00"}}>管道电伴热</Link>
                        </li>
                        <li className={classes.ProductItem}>
                            <Link to="/solutions/roof">屋面除雪融冰</Link>
                        </li>
                        <li className={classes.ProductItem}>
                            <Link to="/solutions/road">道路除雪融冰</Link>
                        </li>
                        <li className={classes.ProductItem}>
                            <Link to="/solutions/runway">机场跑道融雪除冰</Link>
                        </li>
                        <li className={classes.ProductItem}>
                            <Link to="/solutions/firefighting">消防电伴热</Link>
                        </li>
                        <li className={classes.ProductItem}>
                            <Link to="/solutions/unit">居民住宅</Link>
                        </li>
                    </ul>
                </div>
                <section className={classes.CableSection}>
                    <div className={classes.Header}>
                        <a className={classes.Anchor} id="advanage"></a>
                        <h2>管道电伴热</h2>
                        <hr />
                    </div>

                    <div className={classes.Text}>
                        <img src={管道电伴热1} style={{margin:'0 15%',marginTop:'18px', width: '70%'}}/>

                        <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
                            需求特点：在环境寒冷下，需要保证管道内液体的温度，长（短）距离防冻保温、伴热均匀，能根据管道的形状排布。
                        </p>
                        <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
                            解决方案：热源采用瑞海自限温电伴热带或者恒功率伴热带配合瑞海智能热控系统。

                        </p>
                        <img src={管道电伴热2} style={{margin:'0 15%',marginTop:'18px', width: '70%'}}/>
                        <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
                            热源采用管道自限温电伴热带或者恒功率伴热带通过自身发热温度，可以及时补充流体损失的热量，使得温度保持在允许范围内。可以折叠交叉的特性，更加安全可靠、发热均匀。节能省钱，电伴热花费仅是传统蒸汽伴热的一半。配合瑞海的互联网计算机集中控制系统及内外温度感应设备，根据设定值自动运行启停。能有效降低运行成本，延长使用寿命。
                        </p>


                    </div>
                </section>
            </main>
        </Layout>
    );
};

export default solutions;