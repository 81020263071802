import React from "react";
import classes from "./NewsSection.module.scss";
import Cases from "./Cases/Cases";
import News from "./News/News";
const NewsSection = (props) => {
  return (
    <section className={classes.NewsSection}>
      <Cases />
      <News />
    </section>
  );
};

export default NewsSection;
