import React from "react";
import classes from "./Footer.module.scss";
import wechatIcon from "../../assets/wechatIcon.png";
import wechatQR from "../../assets/wechatQR.png";
import weiboIcon from "../../assets/weiboIcon.png";
import weiboQR from "../../assets/weiboQR.png";
import chromeIcon from "../../assets/chromeIcon.png";
import chromeQR from "../../assets/chromeQR.png";
import 微博 from "../../assets/微博.png"
import 知乎 from "../../assets/知乎.png"
import wechat from "../../assets/wechatQR.jpg"
const Footer = (props) => {
  return (
    <footer className={classes.Footer}>
      <div className={classes.Contact}>
        <h3>山西瑞海节能网控电热集中供暖有限公司</h3>
        <p>地址：山西省 太原市 小店区 龙城北街龙城 壹号</p>
        <p>联系邮箱：qinyang@rhthermo.com &nbsp;&nbsp;&nbsp;  联系电话：0351-2334100</p>
        <p>© 2021 RH Thermo All Rights Reserved</p>
      </div>
      <div className={classes.SocialMedia}>
        <div className={classes.Chrome}>
          <img className={classes.Icon} src={chromeIcon} alt="" />
          <img className={classes.QR} src={知乎} alt="" />
        </div>
        <div className={classes.Weibo}>
          <img className={classes.Icon} src={weiboIcon} alt="" />
          <img className={classes.QR} src={微博} alt="" />
        </div>
        <div className={classes.Wechat}>
          <img className={classes.Icon} src={wechatIcon} alt="" />
          <img className={classes.QR} src={wechat} alt="" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
