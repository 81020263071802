import React from "react";
import classes from "./productPage.module.scss";
import cables from "../../assets/cables.png";
import plane from "../../assets/plane.png";
import chairs from "../../assets/chairs.png";
import towerCrane from "../../assets/towerCrane.png";
import Layout from "../../hoc/Layout/Layout";
import Jumbotron from "../../components/Jumbotron/Jumbotron";
import home from "../../assets/productPage/banner_home.png";
import lane from "../../assets/productPage/banner_lane.png";
import campus from "../../assets/imgs/fuwu/banner.png";
import hospital from "../../assets/productPage/dianrelan_banner4_hospital.png";
import office from "../../assets/productPage/dianrelan_banner5_officebuilding.png";
import road from "../../assets/productPage/dianrelan_banner_road.png"
import { Link } from "react-router-dom";
import fare from "../../assets/productPage/fare_03.png";
import yingyon from "../../assets/productPage/yingyon_03.png";
import fanzi from "../../assets/productPage/fnzi2.1830ac13.png";
import 温控器 from "../../assets/productPage/温度控制.png";
import daolu from "../../assets/minimg/3.png";
import 操控 from "../../assets/productPage/03操控.jpg";
import che from "../../assets/productPage/che.png";
import 家 from "../../assets/productPage/05家.png";
import dianlan from "../../assets/productPage/dianlan.png";
import yychangjing from "../../assets/imgs/fuwu/4.jpg";
import wendu from "../../assets/productPage/11.png";
import ipad from "../../assets/minimg/1.png";
import anquan from "../../assets/minimg/6.png";
import headbanner2 from "../../assets/headbanner2.mp4"
 class  productPagedianrelan  extends React.Component {
   constructor(props) {
     super(props)
     this.state = {
       flag: false,
       flag1: false,
       flag2: false,
       flag3: false,
       flag4: false,
        flag5:false
      
       
     }
        
   }
   
   hovers() {
     this.setState({
       flag: true,
       visibility:"visible"
     })
   }
   hovers1() {
      this.setState({
       flag1: true,
       visibility1:"visible"
     })
   }
   hovers2() {
      this.setState({
       flag2: true,
       visibility2:"visible"
     })
   }
   hovers3() {
       this.setState({
       flag3: true,
       visibility3:"visible"
     })
   }
   hovers4() {
       this.setState({
       flag4: true,
       visibility4:"visible"
       })
      
   }
   hovers5() {
      this.setState({
          flag5:true,
       visibility5:"visible"
     })
   }
   mouseout() {
      this.setState({
       flag: false,
       visibility:"hidden"
     })
   }
     mouseout1() {
      this.setState({
       flag1: false,
       visibility1:"hidden"
     })
   }
   mouseout2() {
     this.setState({
           flag2: false,
       visibility2:"hidden"
       })
   }
   mouseout3() {
      this.setState({
           flag3: false,
       visibility3:"hidden"
       })
   }
   mouseout4() {
      this.setState({
           flag4: false,
       visibility4:"hidden"
      })
    
   }
   mouseout5() {
      this.setState({
           flag5:false,
       visibility5:"hidden"
       })
   }
   render() {
  
      return (
    <Layout>
        <a href="/products/heat-cable"><video src={headbanner2} width="100%" height="400px"
           x5-playsinline="" playsinline="" webkit-playsinline=""
         loop autoPlay muted></video></a>
      <main className={classes.Main}>
        <div className={classes.ProductContainer}>
          <h1>产品展示</h1>
          <ul className={classes.ProductList}>
            <li className={classes.ProductItem}>
              <Link to="/products/features">能源云平台</Link>
            </li>
            <li className={classes.ProductItem} style={{border: "1px #f56a00 solid"}}>
              <Link to="/products/heat-cable" style={{color:"#f56a00"}}>发热电缆一体化服务</Link>
            </li>
            <li className={classes.ProductItem}>
              <Link to="/products/heat-power">电伴热一体化服务</Link>
            </li>
            <li className={classes.ProductItem}>
              <Link to="/products/heat-air">
                空气源热泵一体化服务
              </Link>
            </li>
            <li className={classes.ProductItem}>
              <Link to="/products/gas-boiler">燃气锅炉一体化服务</Link>
            </li>
          </ul>
        </div>
        <section className={classes.CableSection}>
          <div className={classes.Header}>
           
            <a className={classes.Anchor} id="dianrelan"></a>
            <h2>电热缆智控</h2>
            <hr />
          </div>

          <div className={classes.Text}>
            <img src={cables} loading="lazy" className={classes.CablesImage} />
            <p>
              瑞海热控严选市面上热效率最高的发热电缆厂商，采用世界最前沿的高灵敏度的合金电阻材料或碳纤维发热材料。将发热电缆预埋在指定供暖区域，通电使发热电缆发热，将舒适、持续、稳定的热量以热传导的方式输送给用户，整体经济环保。瑞海热控会将发热电缆预埋在指定供暖区域，通电使发热电缆发热，将舒适、持续、稳定的热量以热传导(对流)的方式传输出去。
            </p>
            <p>
              由于瑞海热控的发热电缆采暖系统适应于各种应用场景、经济环保、安全舒适、稳定性高、成本低等特性，是瑞海热控最成熟的供热解决方案之一。瑞海热控的发热电缆采暖系统包含有：
            </p>
            <p>
              1).按照客户的需求定制的发热电缆
              瑞海热控可以根据客户的使用场景和使用特点等需求，深度定制电缆结构和材料，满足客户对导热性、耐腐蚀性、抗冲击性、抗潮性等个性化的需求。
            </p>
            <p>
              2).发热电缆智能温控管理技术
              瑞海热控会采用先进的大数据云控制技术，对发热电缆的供暖时段、感应预热、分区供热等进行管理，通过房间的尺寸，容积、布局等智能划分供热区域，以满足每个供热区域每天不同时段的温度需求。利用声音，压感、运动检测、及其移动数据传输等高新技术精确定位。
            </p>
            <p>
              供热区域的供热需求并作出相应反应，实现人在供暖，人走停暖，大幅度减少能源消耗。瑞海热控的发热电缆采暖系统被广泛运用于综合写字楼、医院、校园、酒店、商场、楼宇集群等大型空间的综合供热。也在有专项用途的建筑或工程中有出色的表现，例如公路、桥梁、隧道、机场跑道、礼堂、酒窖、体育场馆、畜牧养殖场、土壤加热等等。
            </p>
              </div>   
            
              <div className={classes.fangzi}>
                 <img className={classes.fangzhi_pic} src={fanzi}></img>     
               </div>
              {/* <section className={classes.Heating}>
              <div className={classes.wuzhi}>   
                       <ul>
                      <li>发热电缆</li>
                      <li> <img  className={classes.autoying_img}  src={fare}></img></li>
                    </ul>
                     <ul>
                      <li>应用场景</li>
                      <li> <img  className={classes.autoying_img} src={yingyon}></img></li>
                    </ul>
                  
            </div>
          </section> */}
            
        </section>
        <section className={classes.GallerySection}>
          <div className={classes.Header}>
            <h2>经典应用场景</h2>
            <hr />
          </div>
          <div className={classes.Gallery}>
            <figure>
              <img src={chairs} />
              <figcaption>
                居民住宅需要电缆发热效果均一、寿命长、安全、低辐射。我们的TXLP（G），采用合金发热丝，发热效果均一稳定。复合交联绝缘层使得电缆更绝缘、更安全，加入铝复合膜后的屏蔽层可以有效降低电磁辐射，寿命长达50年。
              </figcaption>
            </figure>
            <figure>
              <img src={plane} />
              <figcaption>
                部分应用场景有特殊的抗压需求，例如飞机跑道需要电缆加热快、抗高强度冲击、抗潮、少弯折以增加跑道强度。我们的融雪发热电缆HM-cable，采用特殊工艺的外壳，提高了机械强度、导热性和耐腐蚀性。此外使用双导结构，不但易于安装，而且不需要弯折。
              </figcaption>
            </figure>
            <figure>
              <img src={towerCrane} />
              <figcaption>
                基于发热电缆技术设计的瑞海热控加热垫可对户外区域和机械快速解冻。发热垫的形状易于铺设和连接，可以灵活移动。预制插头，即插即用。同时发热垫正反面材质不同，使电缆在严苛的户外环境下可以高效加温、减少热量损耗。
              </figcaption>
            </figure>
              </div>
                <div className={classes.yychangjing}>
                <img src={yychangjing}></img>
                 <p className={classes.yychangjing_title}><span>道路化雪</span> <span>地面电暖</span> <span>水产养殖</span><span>土壤加热</span> </p>
              </div>
        </section>
        <section className={classes.AboutSection}>
          <div className={classes.Header}>
            <h2>相关解决方案</h2>
            <hr />
          </div>
          <a href="/solutions/unit"><img className={classes.Banner} src={home} alt="" height="250px" width="1152px"/></a>
          <a href="/solutions/runway"><img className={classes.Banner} src={lane} alt="" height="250px" width="1152px"/></a>
          <a href="/solutions/school"><img className={classes.Banner} src={campus} alt="" height="250px" width="1152px"/></a>
          <a href="/solutions/hospital"><img className={classes.Banner} src={hospital} alt="" height="250px" width="1152px"/></a>
          <a href="/solutions/office"><img className={classes.Banner} src={office} alt="" height="250px" width="1152px"/></a>
          <a href="/solutions/road"><img className={classes.Banner} src={road} alt="" height="250px" width="1152px"/></a>

        </section>
      </main>
    </Layout>
  );
   }
  
};

export default productPagedianrelan;

