import React from "react";
import classes from "./solutionsPage.module.scss";
import cables from "../../assets/cables.png";
import Layout from "../../hoc/Layout/Layout";
import Jumbotron from "../../components/Jumbotron/Jumbotron";
import { Link } from "react-router-dom";
import 校园多楼宇综合供热1 from "../../assets/校园多楼宇综合供热1.jpg";
import 校园多楼宇综合供热2 from "../../assets/校园多楼宇综合供热2.jpg";
import 校园多楼宇综合供热5 from "../../assets/校园多楼宇综合供热5.jpg";


const solutions = () => {
  return (
    <Layout>
      <Jumbotron page="subPage" />
      <main className={classes.Main}>
        <div className={classes.ProductContainer}>
          <h1>解决方案</h1>
          {/* to do scroll up */}
          <a className={classes.Anchor} id="solutions"></a>
          <ul className={classes.ProductList}>
            <li className={classes.ProductItem}>
              <Link to="/solutions/hospital">医院</Link>
            </li>
            <li className={classes.ProductItem} style={{border: "1px #f56a00 solid"}}>
              <Link to="/solutions/school" style={{color:"#f56a00"}}>学校</Link>
            </li>
            <li className={classes.ProductItem}>
              <Link to="/solutions/office">机关单位、写字楼</Link>
            </li>
            <li className={classes.ProductItem}>
              <Link to="/solutions/mall">大型商场、会议中心</Link>
            </li>
            <li className={classes.ProductItem}>
              <Link to="/solutions/tunnel">管道电伴热</Link>
            </li>
            <li className={classes.ProductItem}>
              <Link to="/solutions/roof">屋面除雪融冰</Link>
            </li>
            <li className={classes.ProductItem}>
              <Link to="/solutions/road">道路除雪融冰</Link>
            </li>
            <li className={classes.ProductItem}>
              <Link to="/solutions/runway">机场跑道融雪除冰</Link>
            </li>
            <li className={classes.ProductItem}>
              <Link to="/solutions/firefighting">消防电伴热</Link>
            </li>
            <li className={classes.ProductItem}>
              <Link to="/solutions/unit">居民住宅</Link>
            </li>
          </ul>
        </div>
        <section className={classes.CableSection}>
          <div className={classes.Header}>
            <a className={classes.Anchor} id="advanage"></a>
            <h2>校园多楼宇综合供热</h2>
            <hr />
          </div>

          <div className={classes.Text}>
            <img src={校园多楼宇综合供热1} style={{margin:'0 15%',marginTop:'18px', width: '70%'}}/>

            <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
              需求特点：人员流动性大、用热时效性强带来的供热需求复杂、楼宇种类较多，以及需要考虑整体运行的经济性。
            </p>
            <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
              解决方案：热源采用瑞海热控发热电缆、瑞海空气源热泵、瑞海天然气锅炉配合瑞海智能热控系统的建筑集群控制技术。
            </p>

            <img src={校园多楼宇综合供热2} style={{margin:'0 15%',marginTop:'18px', width: '70%'}}/>

            <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
              针对人员流动性大的教学楼和学生宿舍，采用瑞海发热电缆与瑞海空气源热泵相结合的多种热源方式。配合瑞海热控的互联网计算机集中控制系统，能快速升温，精确控温，根据每个用暖点位要求不同随时改变供暖温度及时间。空气源热泵机组联网控制，解决夏季供冷冬季供暖的需求。
            </p>

            <img src={校园多楼宇综合供热5} style={{margin:'0 15%',marginTop:'18px', width: '70%'}}/>

            <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
              针对供热供冷时间短，却有很强时效性，还需要大量高温用水的食堂，瑞海天然气锅炉组配合瑞海互联网计算机集中控制系统就能完美解决这个问题。瑞海天然气锅炉节能环保、热效率高，可同时满足供暖用水和烹饪用水的复杂需求。此外，根据饭点，提前通过风机盘管快速升温，减少供暖时间，节约能耗。
            </p>

          </div>
        </section>
      </main>
    </Layout>
  );
};

export default solutions;
