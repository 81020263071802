import React from "react";
import classes from "./solutionsPage.module.scss";
import cables from "../../assets/cables.png";
import Layout from "../../hoc/Layout/Layout";
import Jumbotron from "../../components/Jumbotron/Jumbotron";
import { Link } from "react-router-dom";
import 居民住宅一体化供暖1 from "../../assets/imgs/changjing/2.jpg";
import 居民住宅一体化供暖2 from "../../assets/居民住宅一体化供暖2.jpg";
import 居民住宅一体化供暖3 from "../../assets/居民住宅一体化供暖3.jpg"
import 居民住宅一体化供暖4 from "../../assets/居民住宅一体化供暖4.jpg"

const solutions = () => {
  return (
      <Layout>
        <Jumbotron page="subPage" />
        <main className={classes.Main}>
          <div className={classes.ProductContainer}>
            <h1>解决方案</h1>
            {/* to do scroll up */}
            <a className={classes.Anchor} id="solutions"></a>
            <ul className={classes.ProductList}>
              <li className={classes.ProductItem}>
                <Link to="/solutions/hospital">医院</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/school">学校</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/office">机关单位、写字楼</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/mall">大型商场、会议中心</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/tunnel">管道电伴热</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/roof">屋面除雪融冰</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/road">道路除雪融冰</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/runway">机场跑道融雪除冰</Link>
              </li>
              <li className={classes.ProductItem}>
                <Link to="/solutions/firefighting">消防电伴热</Link>
              </li>
              <li className={classes.ProductItem} style={{border: "1px #f56a00 solid"}}>
                <Link to="/solutions/unit" style={{color:"#f56a00"}}>居民住宅</Link>
              </li>
            </ul>
          </div>
          <section className={classes.CableSection}>
            <div className={classes.Header}>
              <a className={classes.Anchor} id="advanage"></a>
              <h2>居民住宅一体化供暖</h2>
              <hr />
            </div>

            <div className={classes.Text}>
              <img src={居民住宅一体化供暖1} style={{margin:'0 15%',marginTop:'18px', width: '70%'}}/>

              <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
                需求特点：较高的舒适性需求、精确而经济的计费，满足每家居民个性化的需求和整体运行的稳定

              </p>
              <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
                解决方案：热源采用瑞海热控发热电缆、瑞海空气源热泵、瑞海天然气锅炉配合瑞海互联网计算机集中控制系统、瑞海热控建筑集群控制系统

              </p>

              <img src={居民住宅一体化供暖2} style={{margin:'0 15%',marginTop:'18px', width: '70%'}}/>

              <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
                采用瑞海发热电缆与瑞海空气源热泵相结合的多种热源方式。配合瑞海热控的互联网计算机集中控制系统，能精确控温，根据每个用暖点位要求不同随时改变供暖温度及时间。空气源热泵机组联网控制，解决夏季供冷冬季供暖的需求。
              </p>


              <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
                瑞海热控的互联网计算机集中控制系统在舒适性上能给用户带来极大的享受，根据各个房间的温度、湿度，计算凝露点，调节水温和设备功率，使得风机盘管吹出来的风更加接近人体舒适温度，也不会降低房间湿度。让人不会得空调病。
              </p>

              <img src={居民住宅一体化供暖3} style={{margin:'0 15%',marginTop:'18px', width: '70%'}}/>
              <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
                针对天然气廉价的区域，且居民愿意使用集中生活用水提供的小区，可以使用瑞海天然气锅炉组配合瑞海互联网计算机集中控制系统。瑞海天然气锅炉节能环保、热效率高，可同时满足供暖用水和生活及烹饪用水的复杂需求。
              </p>
              <img src={居民住宅一体化供暖4} style={{margin:'0 15%',marginTop:'18px', width: '70%'}}/>
              <p style={{marginTop: '18px', textAlign: 'justify', wordBreak: 'break-all'}}>
                在个性化的运行方面，瑞海集中控制系统根据每家每户的设定值及实施数据自动运行、同时自动存储运行数据、实时显示当前运行情况，实现个性化的设定、精确的计费以及稳定安全整体运行。
              </p>

            </div>
          </section>
        </main>
      </Layout>
  );
};

export default solutions;