import React from "react";
import classes from "./productPage.module.scss";
import cables from "../../assets/cables.png";
import plane from "../../assets/plane.png";
import chairs from "../../assets/chairs.png";
import towerCrane from "../../assets/towerCrane.png";
import Layout from "../../hoc/Layout/Layout";
import Jumbotron from "../../components/Jumbotron/Jumbotron";
import hospital from "../../assets/productPage/dianrelan_banner4_hospital.png";
import office from "../../assets/productPage/dianrelan_banner5_officebuilding.png";
import kongqiyuanrebeng from "../../assets/productPage/kongqiyuanrebeng.png"
import livingroom from "../../assets/productPage/livingroom.png"
import mall from "../../assets/productPage/architecture.png"
import highendhouse from "../../assets/productPage/highendhouse.png"
// import head from "../../assets/productPage/kongqiyuanrebeng_head_picture.gif"
import { Link } from "react-router-dom";
import campus from "../../assets/imgs/fuwu/banner.png";
import 空气源热泵2 from "../../assets/imgs/fuwu/6.jpg";
import 办公楼楼宇智能化管理2 from "../../assets/办公楼楼宇智能化管理2.jpg";
import 机组 from "../../assets/productPage/机组_03.png";
import 机组部件 from "../../assets/productPage/机组部件_03.png";
import 单双机 from "../../assets/productPage/导出圆/2-02.png";
import compressor from "../../assets/productPage/机组00_03.png";
import huangre from "../../assets/productPage/换热_03.png";
import mukuaihua from "../../assets/productPage/mukuaihua_06.png";
import mukuaihua2 from "../../assets/productPage/mukuaihua_08.png";
import mukuaihua3 from "../../assets/productPage/mukuaihua_03.png";
import 机组2 from "../../assets/productPage/机组2222_03.png";
import lingyu from "../../assets/imgs/fuwu/2.jpg";
import Screw1 from "../../assets/productPage/1-1.png";
import Screw2 from "../../assets/productPage/1-2.png";
import Screw3 from "../../assets/productPage/1-3.png";
import Screw4 from "../../assets/productPage/1-4.png";
import Screw5 from "../../assets/productPage/1-5.png";
import Screw6 from "../../assets/productPage/1-6.png";
import arrow from "../../assets/productPage/arrow_03.png";
import headbanner4 from "../../assets/headbanner4.mp4"
const productPagekongqiyuanrebeng = () => {
  return (
    <Layout>
    <a href="/products/heat-air"><video src={headbanner4} width="100%" height="400px"
       x5-playsinline="" playsinline="" webkit-playsinline=""
     loop autoPlay muted></video></a>
      <main className={classes.Main}>
        <div className={classes.ProductContainer}>
          <h1>产品展示</h1>
          <ul className={classes.ProductList}>
            <li className={classes.ProductItem}>
              <Link to="/products/features">能源云平台</Link>
            </li>
            <li className={classes.ProductItem}>
              <Link to="/products/heat-cable">发热电缆一体化服务</Link>
            </li>
            <li className={classes.ProductItem}>
              <Link to="/products/heat-power">电伴热一体化服务</Link>
            </li>
            <li className={classes.ProductItem} style={{border: "1px #f56a00 solid"}}>
              <Link to="/products/heat-air" style={{color:"#f56a00"}}>
                空气源热泵一体化服务
              </Link>
            </li>
            <li className={classes.ProductItem}>
              <Link to="/products/gas-boiler">燃气锅炉一体化服务</Link>
            </li>
          </ul>
        </div>
        <section className={classes.CableSection}>
          <div className={classes.Header}>
            <a className={classes.Anchor} id="kongqiyuanrebeng"></a>
            <h2>空气源热泵智控</h2>
            <hr />
          </div>

          <div className={classes.Text}>
            <img src={kongqiyuanrebeng} loading="lazy" className={classes.CablesImage} />
            <p>
              瑞海热控的空气源热泵系统有冷暖双供，节能环保、体感舒适等特点。通过瑞海热控的智能控制，可以实现一部气源热泵机组就能提供提冷/热供空调、地暖、热水供应、恒温储藏等多功能一体化服务。瑞海热控通过高精准的温度、湿度、气压探测器能准确收集环境信息，制定最大化程度的空气蓄/放热方案，精确变频控制机组功率，高效集热/制冷。出色的热回收技术能大幅度回收再利用排向外界的废热，大大提升节能减排的效果。瑞海热控可以对各个分机盘管和热水阀门智能控制，结合室温给供暖区域提供最舒适的冷/热供应。瑞海热控的空气源热泵系统在居民住宅、别墅、大型商场、写字楼、仓库、酒窖、会议中心都有广泛的使用。
            </p>
            <img src={空气源热泵2} loading="lazy" style={{margin:'0 15%',marginTop:'18px', width: '70%'}}/>

          </div>
        </section>

         <section className={classes.CableSection}>
          <div className={classes.Header}>
            <a className={classes.Anchor} id="kongqiyuanrebeng"></a>
            <h2>空气源热泵系统</h2>
            <hr />
          </div>

          <div className={classes.Text_unit}> 
            <p >
            瑞海热控的空气源热泵系统针对市场需求和智能化进步，不断革新升级，采用创新双级压缩机和喷气增焓技术，大幅提升了机组整体性能。实现了一台气源热泵机组，就可提供冷/热双供空调、地暖、热水供应、恒温储藏等多功能一体化服务。通过瑞海热控的智能控制系统，设备在节能环保上将达到极致。
            </p>    
            <p ><span className={classes.ruhai_title}>瑞海螺杆式超低环温机组<i className={classes.ruhai_title_mao}>:</i></span><span>瑞海螺杆式超低环温空气源热泵机组专门针对北方分布式集中供暖市场。单机即可覆盖10000平方米以上范围的居民小区及大型商业建筑供暖，尤其适用于大型工业应用场景，低温环境温度下能效比极高，是替代燃煤锅炉的完美解决方案，适用于大型客户进行选择。</span></p>
            {/* <b  className={classes.pump_unit_yousi2}>机组优势</b> */}
          <div className={classes.pump_unit}>
              <h3 className={classes.mast}>
                <img className={classes.jizu} src={机组} loading="lazy"></img>
                <p className={classes.arrow}> <img className={classes.jzbz} src={arrow}  loading="lazy" ></img><img className={classes.jzbz} src={arrow} loading="lazy"></img><img className={classes.jzbz} src={arrow} loading="lazy"></img></p>
                <div className={classes.Screw_BOX}>
                  <p className={classes.Screw}>
                  <img src={Screw1} loading="lazy"></img>
                   <span>单双双极压缩技术,真正二级压缩，强劲补气增焓。高低压级模块化设计，系统无高低压差切换的剧烈变化，保证机组高可靠度和舒适度。</span>
                </p>
                 <p className={classes.Screw}>
                  <img src={Screw2} loading="lazy"></img>
                   <span>v型翅片盘管结构,气流布局更合理,分速分布均匀,空间利用率高。</span>
                </p>
                 <p className={classes.Screw}>
                  <img src={Screw3} loading="lazy"></img>
                   <span>高效轴式流分机,叶片形状精心设计配合超低温空气源热泵机组特殊的量及分压要求,经过严格动静二种平衡检测,效率高、振动小、噪声低。</span>
                </p>
                  </div>
               </h3>
            </div>
             <hr className={classes.hengxian2}></hr>
              <p ><span className={classes.ruhai_title}>多功能串联机组<i className={classes.ruhai_title_mao}>:</i></span><span>采用国际领先水平的喷气增焓技术，可靠性强，制热量比普通热泵提升20%。单机可覆盖2000——10000平方米供暖范围，根据用户需求能施行灵活组合供暖。搭载自主研发的智慧能源云平台，采用智能热控系统统筹管理，冬天可提供舒适的地暖，夏天提供中央冷气，实现一机多用，十分适用于小中型客户选用。</span></p>
               {/* <b  className={classes.pump_unit_yousi2}>机组优势</b> */}
            <div className={classes.pump_unit}>
              <h3 className={classes.mast}>
                <img className={classes.jizu} src={机组2} loading="lazy"></img>
                 <p className={classes.arrow}> <img className={classes.jzbz} src={arrow} loading="lazy"></img><img className={classes.jzbz} src={arrow} loading="lazy"></img><img className={classes.jzbz} src={arrow} loading="lazy"></img></p>
                <div className={classes.Screw_BOX}>
                  <p className={classes.Screw}>
                  <img src={Screw4} loading="lazy"></img>
                   <span>高效亲水铝箔换热器,集中进风,增加对流换热面积，整体提升换热效率。多重图层保护,有效降低环境污染对换热器腐蚀。</span>
                </p>
                 <p className={classes.Screw}>
                  <img src={Screw5} loading="lazy"></img>
                   <span>高效轴流分机,3D 匀速出风,风量大,噪音低。</span>
                </p>
                 <p className={classes.Screw}>
                  <img src={Screw6} loading="lazy"></img>
                   <span>智能除霜防冻保护,有效控制机组除霜化霜制热衰减。</span>
                </p>
                  </div>
               </h3>
            </div>
             <hr className={classes.hengxian2}></hr>
            <div className={classes.mokuaihua}>
               <p ><span className={classes.ruhai_title}>家用模块化机组<i className={classes.ruhai_title_mao}>:</i></span><span>在集控系统中包含了多个独立设置的空气源、温度传感控制模块和云服务器。有效地对用热能耗进行有效管控，减少空气源主机运行频率。可实现一户一机，灵活性极高。适合个人和小型客户选用。</span></p>
                <p className={classes.mukuaihua}>
                  <img src={mukuaihua} loading="lazy"></img>
                   <img src={mukuaihua2} loading="lazy"></img>
                 </p>
             </div>
          </div>
        </section>

        <section className={classes.GallerySection} style={{marginTop:'4rem'}}>
          <div className={classes.Header}>
            <h2>经典应用场景</h2>
            <hr />
          </div>
          <div className={classes.Gallery}>
            <figure>
              <img src={livingroom}  loading="lazy"/>
              <figcaption>
                冬暖夏凉的环境对于居住和工作都有着重要的意义。瑞海热控空气源热泵可以一机满足居民住宅和写字楼的供暖制冷需求。体感舒适度显著性强于中央空调。相较于常规制冷空调只能通过开关压缩机或者变频来调节制冷温度，瑞海热控采用水媒辅助调节技术，使得风机盘管中的冷气温度更加精准、舒适。更不会因为经过导热片的风过于冰冷，而产生不必要的冷凝，带走房间的水气。瑞海热控还拥有凝露点检测技术，可以调节空气湿度，能根据屋内环境提供更具有湿润度和舒适性的出风。
              </figcaption>
            </figure>
            <figure>
              <img src={mall}  loading="lazy"/>
              <figcaption>
                瑞海热控的螺杆空压机组空气源热泵面向大型商场、大型仓库、会议中心等客户。单机采暖制冷可覆盖10000平方米。配合风机盘管，温度调节效率更高。专利技术可以在极寒地区-35℃环境下运行。专利导热剂，使得能效更上一层楼。基于瑞海热控的温控网络，随空间温度调整空气源热泵功率和出水口温度，降低热损耗，运行效率更高，温控开支更低。
              </figcaption>
            </figure>
            <figure>
              <img src={highendhouse}  loading="lazy"/>
              <figcaption>
                对于别墅等高端客户，瑞海热控倾力打造了基于空气源热泵的五恒系统。包含恒温、恒湿、恒氧、恒净、恒静。墙体6面全部铺设温控毛细管，一年四季温度都控制在人体舒适的16~24℃区间。新风系统出口小而多的设计使得房间噪音在不断换气过滤的同时，噪音在20分贝以内。配套湿度和含氧量调控装置让人健康舒适。
              </figcaption>
            </figure>
          </div>
        </section>
         <div className={classes.lingyu}> <img src={lingyu} loading="lazy"></img> <p><span>医院</span><span>别墅</span> <span>商场</span><span>酒店</span><span>洗浴中心</span><span>煤矿</span><span>工厂厂房</span></p> </div>
        <section className={classes.AboutSection}>
          <div className={classes.Header} style={{marginTop:"4rem"}}>
            <h2>相关解决方案</h2>
            <hr />
          </div>
          <a href="/solutions/school"><img className={classes.Banner} src={campus} alt="" height="250px" width="1152px"/></a>
          <a href="/solutions/hospital"><img className={classes.Banner} src={hospital} alt="" height="250px" width="1152px"/></a>
          <a href="/solutions/office"><img className={classes.Banner} src={office} alt="" height="250px" width="1152px"/></a>
        </section>
      </main>
    </Layout>
  );
};

export default productPagekongqiyuanrebeng;
