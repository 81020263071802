import React from "react";
import classes from "./productPage.module.scss";
import cables from "../../assets/cables.png";
import plane from "../../assets/plane.png";
import chairs from "../../assets/chairs.png";
import towerCrane from "../../assets/towerCrane.png";
import Layout from "../../hoc/Layout/Layout";
import Jumbotron from "../../components/Jumbotron/Jumbotron";
import integration from "../../assets/productPage/productfeaturesintegration.png";
import visualization from "../../assets/productPage/可视化2.png";
import 可视化 from "../../assets/productPage/productfeaturevisualization.png";
import 手和ipad from "../../assets/productPage/手和ipad.jpg";
import 集成化1 from "../../assets/imgs/fuwu/5.jpg";
import 集成化2 from "../../assets/productPage/集成化2.png";
import 产品介绍1 from "../../assets/productPage/产品介绍1.png";
import 产品介绍2 from "../../assets/productPage/产品介绍2.png";
import 产品介绍3 from "../../assets/productPage/产品介绍3.png";
import 产品介绍4 from "../../assets/productPage/产品介绍4.png";
import smart from "../../assets/productPage/productfeaturesmart.png";
import diversity from "../../assets/productPage/productfeaturediversity.png";
import { Link } from "react-router-dom";
import 医院综合热能解决方案1 from "../../assets/医院综合热能解决方案1.jpg";
import headbanner1 from "../../assets/headbanner1.mp4"
const productPagefeatures = () => {
  return (
    <Layout>
    <a href="/products/features"><video src={headbanner1} width="100%" height="400px"
       x5-playsinline="" playsinline="" webkit-playsinline=""
     loop autoPlay muted></video></a>
      <main className={classes.Main}>
        <div className={classes.ProductContainer}>
          <h1>产品展示</h1>
          <ul className={classes.ProductList}>
            <li className={classes.ProductItem} style={{border: "1px #f56a00 solid"}}>
              <Link to="/products/features" style={{color:"#f56a00"}}>能源云平台</Link>
            </li>
            <li className={classes.ProductItem}>
              <Link to="/products/heat-cable">发热电缆一体化服务</Link>
            </li>
            <li className={classes.ProductItem}>
              <Link to="/products/heat-power">电伴热一体化服务</Link>
            </li>
            <li className={classes.ProductItem}>
              <Link to="/products/heat-air">
                空气源热泵一体化服务
              </Link>
            </li>
            <li className={classes.ProductItem}>
              <Link to="/products/gas-boiler">燃气锅炉一体化服务</Link>
            </li>
          </ul>
        </div>
        <section className={classes.CableSection}>
          <div className={classes.Header}>
            <h2>产品介绍</h2>
            <hr />
          </div>
          <div className={classes.Text}>
            <p>
              客户安装瑞海智能热控系统RH.OS V3.0.1，并设定好物联网各项热源设备配置之后，便可实现对所有物联网内热源的智能控制。客户可以在控制界面上提出、修改、调整需求，操作系统联通云端从而完成一整套的控制过程。
            </p>
            <img src={产品介绍2}  loading="lazy" style={{margin:'0',marginTop:'18px', width: '100%'}} />
            <p>
              例如，用户要改变预设温度计运行状态，系统根据设定值下发运行指令至楼层控制模块，楼层控制模块根据设定数据不间断采集各个控制节点的数值（室内温度传感器、水温传感器，人员流动传感器等）当室温智能调节。
            </p>
            <img src={产品介绍3} loading="lazy" style={{margin:'0',marginTop:'18px', width: '100%'}}/>
            <p>
              当有差别供暖需求时，可在操作界面直接修改运行数据或调整至预设方案，满足不同人群受热要求。在假期时可调整为假期模式或根据实际需求对值班室等有温度需求的部位单独调温，其他部位保温运行。当系统运行夜间模式或低温保温模式时，系统根据每个温度控制节点反馈的数据，自动调整各个节点阀门的开闭及锅炉的运行情况。所有的运行数据可在控制界面及移动端APP上实时查看，运行数据及运行参数在“云”端存储、运行安全可靠。
            </p>
          </div>
        </section>

        <section className={classes.CableSection}>
          <div className={classes.Header}>
            <h2>集成化</h2>
            <hr />
          </div>

          <div className={classes.Text}>
            <img src={integration} loading="lazy" className={classes.CablesImage} />
            <p>
              对考虑地域气候因素，用户差异化的冷热源诉求，瑞海热控针对性地开发了跨冷热源与复合热源的高集成化融合供暖系统。在兼容其他热源的基础上，因地制宜地达到效率与节能的兼配合一。瑞海智能热控系统RH.OS V3.0.1基于瑞海热控十余年的综合管理恒温控制技术，依靠高集成度的一体化的解决方案和对相关软硬件与关键数据灵活掌控，做到对各类型热源的协调和统一，实现多热源同时供暖，突出局部供热最灵活、整体供热最优的特点，同时满足客户对供暖舒适度、运营经济性、节能可持续发展、新旧设备兼容性、设备持久性等复杂需求。
            </p>
            <p>
              不同热源有不同的优点和适用范围，瑞海能根据客户的个性化需求，定制专属的供热方案。例如发热电缆结构简单，造价和维护成本低，发热效果均一。但是只能通过热传导方式来进行加热，需要长时间预热。空气源热泵能效比高，体感舒适，温控迅速，冷暖双供。但是造价高昂，在开放环境也无法使用。天然气锅炉使用天然气，可同时满足用户的生活用水和供暖用水需求，但是热效率不如空气源热泵。目前瑞海智能热控系统已经支持市面上绝大多数热源，如空调、空气源热泵、天然气、伴热带和发热电缆等等。在瑞海智能热控系统的加持下，能把各种热源的优势发挥到极致，成本收益会有一个质的飞跃。
            </p>
            <img src={集成化1}  loading="lazy" style={{margin:'0',marginTop:'18px', width: '100%'}}/>
            <p>
              瑞海智能热控系统能根据用户的需求和预算，做出热源之间的权衡，灵活在不同的局部采取不同热源方案，达到整体最优。
            </p>
            <img src={集成化2}  loading="lazy" style={{margin:'0 25%',marginTop:'18px', width: '50%'}}/>
            <p>
              除此之外，瑞海智能热控系统RH.OS V3.0.1还有实时大数据接口，针对一些决定性的影响因素进行云计算分析，动态模拟运行情况选择最优的供暖方案。例如，发热电缆和空气源热泵等设备对电价的敏感度非常高，电价的一点点变化都会引起整体运营成本的大幅变动。瑞海智能热控系统会结合实时峰谷用电负载和价格的大数据对热源组合进行适当调整，在峰电价到来之前，使用发热电缆储存好足够的热能缓慢放热或切换成其他更经济的热源。在满足供热需求的情况下，选择最经济实惠的热源组合。
            </p>
            <p style={{marginLeft:'350px', marginTop:'25px'}}>
              集成化优势总结：结合各热源的优点；局部灵活；整体最优。
            </p>
          </div>
        </section>
        <section className={classes.CableSection}>
          <div className={classes.Header}>
            <h2>可视化</h2>
            <hr />
          </div>

          <div className={classes.Text}>
            <img src={可视化} loading="lazy" className={classes.CablesImage} />
            <p>
              瑞海智能热控系统RH.OS V3.0.1的操作界面采用扁平化设计，界面简洁直观，可视化程度高。无论是对单体设备进行控制还是实施区域集中控制，瑞海热控的操作界面均能够出色满足需求。建立在与国内顶尖大学合作研发技术基础上的网控数据系统，操作简单，数据实时反馈。对于房间的热量损耗、工作状态、计费情况以及温度曲线都一目了然。瑞海智能热控系统在十年内已经经历了三代产品革新，从最一开始的纯物理独立控制，到机房联控，再到最新的互联网云控，其操作系统的兼容性不断提升，最新的RH.OS V3.0.1兼容电脑Windows系统、苹果系统和安卓系统，仅需一台设备，便可远程监控和控制网络下所有的设备。可以在终端设置不同级别的权限，管理人员、为运维人员和独立用户提供更精准的服务。
            </p>
            <p>
              瑞海热控还可以根据客户的设备的具体厂商、型号、功能量身定做可视化的操空界面。我们将用拟真3D模型把设备还原在控制系统中，并且显示有由精准传感器采集而来的舒适。用户还可以直观检查设备各个部件的具体运行参数，例如，特定部件和位置的温度、湿度、 压力、有害空气浓度、烟雾等等。瑞海热控的工程师会根据这些参数设置相应的预警警报阀值，如果某一参数超过了系统的设定的安全阀值，系统会在3D模型上高亮有安全隐患的部件并发出警报，通知运维人员及时处理。
            </p>
            <img src={visualization} loading="lazy" style={{margin:'0 15%',marginTop:'18px', width: '70%'}}/>
            <p style={{marginLeft:'350px', marginTop:'25px'}}>
              可视化优势：体验舒适; 热效率高; 设备使用便捷。
            </p>
          </div>
        </section>
        <section className={classes.CableSection}>
          <div className={classes.Header}>
            <h2>智能化</h2>
            <hr />
          </div>

          <div className={classes.Text}>
            <p>
              智能化云计算是瑞海智能热控系统的技术保障。瑞海物联网云端每秒都会与我们的控制端发生交互。数据传感器上的任何数据都会随时与瑞海云端进行同步。通过瑞海云计算，监控每个传感器的动态范围，实时调整设备功率，实现辅助节能。同时能按需部署，在所有区域均可设置独立的控制传感器，达到0.5℃的局部精确控温。考虑到不同房间、不同设备的不同需求，客户可以设置延时开关、定时启停、区间温控等精确控制。反应迅速、可靠性高。
            </p>
            <img src={手和ipad} loading="lazy" style={{margin:'0 25%',marginTop:'18px', width: '50%'}}/>
            <p>
              瑞海热控的云计算动态温控模块还会结合实时大数据下的环境特征给系统提供最科学的动态操控建议，例如可根据各地太阳落山时间段自动调整照明时间，会根据天气预报及时调整温度、湿度，为客户带来最舒适的居住体验。
            </p>
            <p style={{marginLeft:'350px'}}>
              智能化优势：通过云计算辅助节能；按需部署；可靠性高。
            </p>
          </div>
        </section>

        <section className={classes.CableSection}>
          <div className={classes.Header}>
            <h2>多样化</h2>
            <hr />
          </div>

          <div className={classes.Text}>
            <img src={diversity} loading="lazy" className={classes.CablesImage} />
            <p>
              瑞海智能热控系统RH.OS V3.0.1的优势综合体现到了应用层面。基于瑞海热控搭建的物联网，控制系统可以和建筑集群的各种设备及其子操作系统无缝连接，实现对物联网内个节点的全方位集中管理、整体联动，从而延长设备的服役寿命、降低能源消耗、实现建筑集群自动化控制。
            </p>
            <p>
              瑞海智能热控系统可以实时与物联网中的各个节点交互，数据传感器上的任何数据都会第一时间与瑞海热控的云端服务器进行同步，管理员也能随时对各个设备下达指令。系统还可以实现设备按照预定工作状态的自动控制。以供暖系统为例，瑞海热控的传感器可以实现对目标区域的温度、湿度、亮度、气压等关键参数的实时监控，对温度进行自动调节。
            </p>
            <p>
              如果温度显著低于设定值，就加大功率，快速升温；当温度接近设定值时，减小功率，降低能耗，缓慢升温；当温度高于设定值时，我们会在这个房间停止供暖；当温度显著高于房屋设定值，房屋也停止了供暖。云控制系统还会综合传感器的数据，云控制系统还能判断区域是否有人供暖需求，如果没有人供暖需求，云控制系统会自动停止供暖，节约能耗。云控制系统还可以自动排查系统中的异常状态，当有疑似异常状态时，会触发警报系统，提醒大楼运维人员到现场查看，排查故障。
            </p>
            <p>
              瑞海建筑集群控制系统，集数据通讯、人机界面、监控服务器及其他外设为一体，可实现集中操作、管理、显示以及报警等。建筑集群物联网联控技术可以完美兼容各种常见设备的控制系统，实行全自动的综合管理，大大提高建筑和设备的安全性、经济性和寿命，为建筑管理者及广大客户提供最有效的信息服务和一个高效、舒适、便利、安全的环境。目前兼容的设备类型有：采暖、通风、电梯、空调监控，给排水监控，配变电与自备电源监控，火灾自动报警，视频监控等。从办公楼、居民楼等常见建筑，乃至礼堂、体育场馆等专项用途的建筑、还有机场、隧道、工厂、化工管道、畜牧养殖场等有特殊需求的工程，都能见到我们瑞海建筑集群控制系统的身影。
            </p>
            <p style={{marginLeft:'300px', marginTop:'25px'}}>
              多样化优势：延长设备的使用寿命；提高设备使用效率；优化设备运行方式
            </p>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default productPagefeatures;
