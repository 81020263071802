import React from "react";
import classes from "./Arrow.module.scss";
const Arrow = (props) => {
  return (
    <div
      className={`${classes.Arrow} ${props.type ? classes[props.type] : ""} ${
        classes[props.order]
      }`}
    >
      <p>{props.children}</p>
    </div>
  );
};

export default Arrow;
