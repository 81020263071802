import React from "react";
import classes from "./Main.module.scss";
import SearchBar from "../SearchBar/SearchBar";
import ProductSection from "./ProductSection/ProductSection";
import Flowchart from "../Flowchart/Flowchart";
import SolutionSection from "./SolutionSection/SolutionSection";
import NewsSection from "./NewsSection/NewsSection";
import Jumbotron from "../Jumbotron/Jumbotron";
import Layout from "../../hoc/Layout/Layout";
import Intelligent from './Intelligent/Intelligent'
const Main = (props) => {
  return (
    <Layout>
      <Jumbotron />
      <main className={classes.Main}>
        <SearchBar />
        <ProductSection />
        <hr className={classes.BreakLine} />
         <Intelligent/>
        {/* <Flowchart /> */}
        {/* <SolutionSection /> */}
        <NewsSection />
      </main>
    </Layout>
  );
};

export default Main;
