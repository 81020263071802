import React from "react";
import classes from "./productPage.module.scss";
import cables from "../../assets/cables.png";
import chairs from "../../assets/chairs.png";
import inventory from "../../assets/productPage/inventory.png";
import towerCrane from "../../assets/towerCrane.png";
import Layout from "../../hoc/Layout/Layout";
import Jumbotron from "../../components/Jumbotron/Jumbotron";
import hospital from "../../assets/productPage/dianrelan_banner4_hospital.png";
import ranqiguolu from "../../assets/productPage/ranqiguolu.png";
import { Link } from "react-router-dom";
import campus from "../../assets/imgs/fuwu/banner.png";
import rangqi from "../../assets/productPage/rangqi_03.png";
import 应用图片 from "../../assets/productPage/应用图片111_03.png";
import 应用图片2 from "../../assets/productPage/应用图片2_03.png";
import 应用图片3 from "../../assets/productPage/应用图片3_03.png";
import RQGL from "../../assets/imgs/fuwu/3.jpg";
import headbanner3 from "../../assets/headbanner3.mp4"
const productPageranqiguolu = () => {
  return (
    <Layout>
    <a href="/products/gas-boiler"><video src={headbanner3} width="100%" height="400px"
       x5-playsinline="" playsinline="" webkit-playsinline=""
     loop autoPlay muted></video></a>
      <main className={classes.Main}>
        <div className={classes.ProductContainer}>
          <h1>产品展示</h1>
          <ul className={classes.ProductList}>
            <li className={classes.ProductItem}>
              <Link to="/products/features">能源云平台</Link>
            </li>
            <li className={classes.ProductItem}>
              <Link to="/products/heat-cable">发热电缆一体化服务</Link>
            </li>
            <li className={classes.ProductItem}>
              <Link to="/products/heat-power">电伴热一体化服务</Link>
            </li>
            <li className={classes.ProductItem}>
              <Link to="/products/heat-air">
                空气源热泵一体化服务
              </Link>
            </li>
            <li className={classes.ProductItem} style={{border: "1px #f56a00 solid"}}>
              <Link to="/products/gas-boiler" style={{color:"#f56a00"}}>燃气锅炉一体化服务</Link>
            </li>
          </ul>
        </div>
          <section className={classes.CableSection}>
          <div className={classes.Header}>
            <a className={classes.Anchor} id="ranqiguolu"></a>
            <h2>燃气锅炉系列</h2>
            <hr />
          </div>

          <div className={classes.Text}>
            <p>
             燃气锅炉系统解决方案采用全预混冷凝锅炉，冷凝燃气锅可充分回收烟气中的显热和地凝结潜热，热效率将大幅提高，满足现在环保最严标准的锅炉，具有高效节能、清洁环保两大优势。可根据客户需求灵活定制风机盘管，地暖等灵活供热终端
            </p>
            <img src={rangqi} className={classes.rangqi}></img>
           
          </div>
        </section>
        <section className={classes.CableSection}>
          <div className={classes.Header}>
            <a className={classes.Anchor} id="ranqiguolu"></a>
            <h2>燃气锅炉智控</h2>
            <hr />
          </div>

          <div className={classes.Text}>
            <img src={ranqiguolu} className={classes.CablesImage} />
            <p>
              燃气锅炉智控系统能整合多个燃气锅炉机组和控制终端，协调各终端实际温度和设定温度，制定出终端热量供应方案。燃气锅炉智控系统根据实时监测的出/回水口温度、流速、流量等相关信息，调节燃气锅炉的运行数量，从而调整整体锅炉机组的燃烧功率和出水口温度，做到精确供暖，降低在各个环节的热损耗，杜绝由于过度加热而造成的浪费。
            </p>
            <p>
              燃气锅炉智控系统能可兼容全类型的燃气锅炉，包括与合作厂商共同研发的智能冷凝燃气锅炉。瑞海智能冷凝燃气锅炉通过对烟气冷凝热交换，降低排烟温度，从而回收热能。在水蒸气转化为液体的过程中，许多潜热被抓取且再次加热，提高热效率，所产生的热效率最高甚至可达109%。智能冷凝燃气锅炉还采用混热燃烧技术，和大尺寸的管道设计，即使长时间使用，也不用担心水垢导致的管道堵塞，从而降低维护成本，延长使用寿命。
            </p>
            <p>
              燃气锅炉智控系统，节能环保、热效率高，可同时满足用户的生活用水和供暖用水需求。在天然气充足的地区和大型集中供暖项目中优势十分明显，非常适用于居民区集中供暖、大型商场、大型仓库、大型会议中心等客户。
            </p>
            <p>
              同时，瑞海热控的锅炉低氮改造也是一大亮点。为达到低氮改造最好的效果，瑞海热控利用先进的全预混低氮冷凝技术。通过了超前的冷凝热回收技术，最大限度利用燃烧产生的热能。使用独特技术将燃料空气充分混合，使其充分燃烧,提供给锅炉以充足的动力。降低燃烧副产物中燃料不充分燃烧带来的杂质，提高尾气纯度。同时利用将出口烟气中的热量最大化回收，大幅度提升热效率。
            </p>
          </div>
        </section>
    
        <section className={classes.GallerySection}>
          <div className={classes.Header}>
            <h2>经典应用场景</h2>
            <hr />
          </div>
          <div className={classes.Gallery_Header}>
               <p>
               <img src={chairs} />
               <span> 无论是新旧居民区的集中供暖，燃气锅炉智控系统都是基于传统集中供暖最好的升级方案。兼容现有集中供暖的管道，无需复杂的重建工作，升级成本低。而瑞海燃气锅炉智控系统基于对热量消耗的精准计算，随时调整燃气锅炉机组的功率。可以做到使用成本低廉、温度控制精确和升级工程简单。</span>
              </p>
                <p>
               <img src={inventory} />
               <span>  对于大型商场、大型仓库、大型会议中心等大型客户，我们则推荐使用智能冷凝燃气锅炉系统。智能冷凝燃气锅炉在能耗上具有系统优势，发热效率更高、维护更轻松简便，这使得长期使用成本更加低廉。此外，还能做到同时供应高低温不同温度的热水，一站式满足商业用水和采暖用水的需求。</span>
             </p>
          </div>
          <div className={classes.RQGL}>
            <img src={RQGL}></img>
             <p className={classes.Heating}><span>家庭供暖</span><span>洗澡热水</span><span>日常生活</span><span>医院</span><span>酒店</span><span>商场</span><span>学校</span></p>
           </div>
        </section>
        <section className={classes.AboutSection}>
          <div className={classes.Header}>
            <h2>相关解决方案</h2>
            <hr />
          </div>
          <a href="/solutions/school"><img className={classes.Banner} src={campus} alt="" height="250px" width="1152px"/></a>
          <a href="/solutions/hospital"><img className={classes.Banner} src={hospital} alt="" height="250px" width="1152px"/></a>
        </section>
      </main>
    </Layout>
  );
};

export default productPageranqiguolu;
