import React from "react";
import classes from "./Flowchart.module.scss";
import Arrows from "./Arrows/Arrows";
import Lists from "./Lists/Lists";
const Flowchart = (props) => {
  return (
    <div className={classes.Flowchart}>
      <Arrows />
      <h4 className={classes.TextBoxOne}>
        <span>瑞海热控</span> <span>技术团队</span>
      </h4>
      <div className={classes.ListContainerOne}>
        <Lists />
        <Lists lists={["收集信息", "实地考察、调研", "采集数据"]} />
        <Lists lists={["可行性分析"]} />
        <Lists
          lists={[
            "制定技术方案",
            "设计施工图纸",
            "制定工期、造价",
            "成本控制计划",
          ]}
        />
        <Lists lists={["联系供应商", "指导施工团队"]} />
        <Lists
          lists={[
            "培训运维人员",
            "指导项目运营",
            "优化运营流程",
            "降低运营成本",
            "缩短流程处理时间",
          ]}
        />
      </div>
      <h4 className={classes.TextBoxTwo}>
        <span>瑞海热控</span>
        <span>咨询团队</span>
      </h4>
      <div className={classes.ListContainerTwo}>
        <Lists
          lists={["接洽客户", "介绍团队", "询问客户问题", "提炼客户需求"]}
        />
        <Lists lists={["处理信息", "分析数据"]} />
        <Lists lists={["帮助客户建立目标", "成本收益分析", "审核项目计划"]} />
        <Lists
          lists={["制定专项策略（节能， 经济， 自动化供暖等）", "审核最佳实践"]}
        />
        <Lists />
        <Lists lists={["绩效评估", "能耗统计分析", "实施监控"]} />
      </div>
    </div>
  );
};

export default Flowchart;
