import classes from  './Intelligent.module.scss'
import Inteimg from '../../../assets/casesPage/瑞海智控.jpg'
import structure from '../../../assets/casesPage/znxt.jpg'
import control from '../../../assets/casesPage/control.jpg'
import analysis from '../../../assets/imgs/home/3.jpg'
import Administration from '../../../assets/casesPage/gli_03.png'
import circle from '../../../assets/casesPage/智能电暖圆形.png'
import Inteimg_zn from '../../../assets/casesPage/zhineng.jpg'
import yuanhuang from '../../../assets/casesPage/yuanhuan.jpg'
import guanli from '../../../assets/imgs/home/2.jpg'
import wuliangwang from '../../../assets/casesPage/WLW.png'
import yunjisuan from '../../../assets/casesPage/yjsuan.png'
import Bim from '../../../assets/casesPage/bim-1.jpg'
import React from "react";

const Intelligent = (props) => {
  return (
    <div className="Intelligent_page">
      <div>
         <p>
          <img src={guanli} className={classes.Administration} loading="lazy"></img>
        </p>
        <p className={classes.intelligent_system}>
          <img className={classes.znimg} src={Inteimg_zn} loading="lazy"></img>
        </p>
        
        <p className={classes.text}>随着互联网时代的到来，信息传输模式发生了翻天覆地的改变，各行各界都在进行数据的改革，人们对于舒适，也有了全新的定义。在供热领域，由于建筑电气化程度不断加强，建筑能耗也随之增加，造成了运行方的能源供给和用户日常使用费用的压力同步增长。由于建筑能耗是一个复杂且受多方因素影响的难题，现阶段简单建立在单一热负荷系数的线性数据分析而建立所谓的“智慧”系统，最后也只能沦为展示厅中尴尬的一块集中传感器显示系统，而跟智能“搭”不上半点关系。</p>
        <p className={classes.text}>如今，供热系统设施设备逐步从单一功能向综合能源系统协同发展。综合能源智能服务成为供热行业新的技术投&nbsp;、研究的重点。然而，市场上
        却缺乏一个能真正融合多种数据来源的动态管控能源管理系统。瑞海热控科研团队针对现状，潜心数载，研发出基于物联网、云计算和智能控制技术的综合能源控制平台——瑞海智慧能源云平台。</p>
        <p className={classes.energy}>智慧能源云系统可进行综合数据处理，发挥信息联动。分析建筑能耗动静态因素，实现数字化管理。</p>
        
        <p>
          <img className={classes.analysis} src={yuanhuang} loading="lazy"></img>
        </p>
       </div>
     </div>
  );
};
export default Intelligent