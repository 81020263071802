import React from "react";
import classes from "./News.module.scss";
// import 瑞海参加热控会得奖照片新闻 from "../../../../assets/瑞海参加热控会得奖照片-新闻.jpg"
import { withRouter } from 'react-router-dom'
import  xieshou from "../../../../assets/img/11.png"
import changuan from "../../../../assets/lanzhou.png"

class News extends React.Component{
 constructor(props) {
    super(props)
 }

 render() {
       return (
    <div className={classes.News}>
      <a  className={classes.backdynamic} href="/news">公司动态</a>
        <div className={`${classes.TextBox1} ${classes.TextBox}`}>
        <a className={classes.changuan} target="_blank" href="https://mp.weixin.qq.com/s/SXJsSErj5o2_vH2-tNYz4w">
          <h4 style={{marginTop:"10px", color:"black"}}>甘肃省兰州市政府、山西甘肃商会赴瑞海考察交流</h4>
          <p>2021.04.03</p>
        </a>
        <div className={`${classes.TextBox3} ${classes.TextBox}`}>
            <a target="_blank" href="https://mp.weixin.qq.com/s/SXJsSErj5o2_vH2-tNYz4w">
              <img  className={classes.xieshou} src={changuan}  loading="lazy" width="100%" style={{marginTop:"5px"}}/>
            </a>
        </div>
        <a target="_blank" href="https://mp.weixin.qq.com/s?__biz=MzI5MDk5MDc2Mg==&mid=100001844&idx=1&sn=eda0720c0eec100e4535067296f4e3d6&scene=19#wechat_redirect">
          <h4 style={{marginTop:"30px", color:"black"}}>瑞海携手太原地铁，助力绿色智慧人文轨道交通</h4>
          <p>2020.12.23</p>
        </a>
        <div className={`${classes.TextBox3} ${classes.TextBox}`}>
          <a target="_blank" href="https://mp.weixin.qq.com/s/JxdbEAeyYl_v5UAMj1LR4A"><img  className={classes.xieshou} src={xieshou} loading="lazy"  width="100%" style={{marginTop:"5px"}}/></a>
        </div>
      </div>

      <div className={`${classes.TextBox4} ${classes.TextBox}`}>

      </div>
    </div>
  );
    }
};

export default  withRouter(News);
