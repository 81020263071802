import React from "react";
import Layout from "../../hoc/Layout/Layout";
import classes from "./processPage.module.scss";
import classes2 from "../productPage/productPage.module.scss"
import Flowchart from "../../components/Flowchart/Flowchart";
import Jumbotron from "../../components/Jumbotron/Jumbotron";
import integration from "../../assets/productPage/productfeaturesintegration.png";
const processPage = () => {
  return (
    <Layout>
      <Jumbotron page="subPage" />
      <main className={classes.Main}>
        <div className={classes.ProcessContainer}>
          <h1>服务流程</h1>
        </div>
        <Flowchart />
          <section className={classes2.CableSection}>
              <div className={classes2.Header}>
                  <h2>运营成本经济可控</h2>
                  <hr />
              </div>

              <div className={classes2.Text}>
                  {/*<img src={integration} className={classes2.CablesImage} />*/}
                  <p>
                      瑞海热控能对项目的精准计费，根据实际产生的服务计费，运营成本清晰透明。依托智能控制，瑞海热控可以做到对运营成本实时调控，依照实际情况优化运营成本。目前，瑞海热控是我国唯一能够利用广域网技术将电网谷期电能应用于建筑物集中蓄热供暖和冰蓄冷制冷的系统集成商，这能为客户节省大量的运营成本。
                  </p>
              </div>
          </section>
          <section className={classes2.CableSection}>
              <div className={classes2.Header}>
                  <h2>区域级设备供货商 </h2>
                  <hr />
              </div>

              <div className={classes2.Text}>
                  {/*<img src={integration} className={classes2.CablesImage} />*/}
                  <p>
                      瑞海热控与多家设备厂商签订了战略协议，与山西地区的设备销售监理了稳定可靠的货源关系。瑞海热控将厂商设备优势直接延伸至应用端，以极快的相应速度，为区域的客户制定出有针对性的设备组合方案。同时，瑞海热控充分利用扁平化、精准化供应链的优势，大大减少供货渠道上的阻力，能以远低于市场价的价格为客户选购相应的设备。
                  </p>
              </div>
          </section>

          <section className={classes2.CableSection}>
              <div className={classes2.Header}>
                  <h2>稳定可靠的大型项目运营经验</h2>
                  <hr />
              </div>

              <div className={classes2.Text}>
                  {/*<img src={integration} className={classes2.CablesImage} />*/}
                  <p>
                      项目覆盖，山西、北京、辽宁等地区，为诸多机构单位提供大型项目一体智能化解决方案与运营维护工作。仅太原学院单个项目便铺设了22万平方米的智能化供热区域，持续稳定服务十数载，系统稳定可靠，运营维护成本极低。此类大型供暖项目长达10年的运维经验，为业内少有。此外，公司还拥有多类型项目的智能化系统开发经验，为医院、学校、商业办公楼、住宅公寓及各类别墅院落等不同类型项目建设了配套智能化系统整合，熟悉各类型智能化设备的开发与应用。提供综合服务、定制化服务的能力突出。
                  </p>
              </div>
          </section>

          <section className={classes2.CableSection}>
              <div className={classes2.Header}>
                  <h2>迅速响应</h2>
                  <hr />
              </div>

              <div className={classes2.Text}>
                  {/*<img src={integration} className={classes2.CablesImage} />*/}
                  <p>
                      公司现有技术通过光纤与4G、5G、微波等无线通讯光纤与微波通讯，可实现对智能化设备的远程调控、精确调控，各项数据实时回馈到控制总机，实现自动预警，这大大降低了运营维护难度和运行成本，可及时暴露系统的故障点，杜绝隐患。公司能够在第一时间发现问题、解决问题，做到小问题1小时内解决，大问题24小时内维修完毕，真正做到不间断供暖。问题响应与维修速度，在业内一直保持领先水平。
                  </p>
              </div>
          </section>
      </main>
    </Layout>
  );
};

export default processPage;
