import React from "react";
import classes from "./KeyWordSearch.module.scss";
const KeyWordSearch = (props) => {
  return (
    <div className={classes.KeyWordSearch}>
      <h4>热门搜索关键词:</h4>
      <a className={classes.Link} target="_blank" href="/products/heat-cable">
        跨热源供热
      </a>
      <a className={classes.Link} target="_blank" href="/products/features">
        物联网
      </a>
      <a className={classes.Link} target="_blank" href="/solutions/school">
        校园综合供暖
      </a>
      <a className={classes.Link} target="_blank" href="/solutions/road">
        融雪除冰
      </a>
    </div>
  );
};

export default KeyWordSearch;
