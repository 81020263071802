import React, { useState } from 'react';
import { withRouter } from 'react-router-dom'
import classes from "./customer.module.scss";
import guanfang from '../../../src/assets/guanfang.jpg'
import kepic from '../../../src/assets/imgs/kfu.jpg'
class Customer extends React.Component{
  constructor(props){
   super(props)
   this.state={
    falg: false,
    kefufalg: false,
    wexxing: false,
    setnamefalg: false,
    supplyfalg: false,
    servicefalg:false,
     icon:'',
    text: "",
    title: "热门问题",
   }
  }
  online() {
    this.setState({
      falg:true
    })
 
  }
  close() {
     this.setState({
      falg:false
    })
  }
  setname() {
  if (this.state.setnamefalg == false) {
      this.setState({
        setnamefalg: true,     
    })
    } else {
      this.setState({
        setnamefalg: false,    
    })
    }  
  }
  supply() {
      if (this.state.supplyfalg == false) {  
      this.setState({
        supplyfalg: true,      
    })
    } else {
      this.setState({
        supplyfalg: false,     
    })
    }
  }
  service() {
      if (this.state.servicefalg == false) {  
      this.setState({
        servicefalg: true,      
    })
    } else {
      this.setState({
        servicefalg: false,     
    })
    }
  }
  kehone() {
    this.setState({
        kefufalg:true
    })
  }
  kehoneout() {
     this.setState({
        kefufalg:false
    })
  }
  Guanfang() {
       this.setState({
         wexxing:true
    })
 
  }
  Guanfangout() {
       this.setState({
         wexxing:false
    }) 
 
 }
 backtranslate() {
  this.props.history.push('/products/features')
 }
 backGas() {
  this.props.history.push('/products/gas-boiler')
 }
 backElectric() {
   this.props.history.push('/products/heat-power')
 }
 backconditioner() {
   this.props.history.push('/products/heat-air')
 }
 backchat() {
  if(navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i)){
   window.open('mqqwpa://im/chat?chat_type=wpa&uin=354612769&version=1&src_type=web&web_src=oicqzone.com')
  } else {
   window.open('tencent://message/?uin=354612769&Menu=yes& Service=300&sigT=42a1e5347953b64c5ff3980f8a6e644d4b31456cb0b6ac6b27663a3c4dd0f4aa14a543b1716f9d45')
}
 
 }
 componentDidMount() {
      
       window.addEventListener('scroll', function (event) {     
      // 滚动的高度
	        const scrollTop = (event.srcElement ? event.srcElement.documentElement.scrollTop : false) 
                || window.pageYOffset
       || (event.srcElement ? event.srcElement.body.scrollTop : 0);
       if (scrollTop>=0) {
        var box = document.getElementById('boxs')
        box.style.bottom = -scrollTop + 'px';
       }
      
   })

 }
 render() {
     const { options, title, text, title1,kefufalg,wexxing} = this.state
   return        <div id="boxs" className={classes.List_name}>
            <div className={this.state.falg == true ? classes.tab_kf1 : classes.tab_kf}>
              <p onClick={() => {
                this.online()
              }}><span className="iconfont" style={{fontSize: '55px'}}>&#xe6f6;</span> <span>在线客服</span></p>

              <h5 className={classes.dianhua}><p onMouseOver={() => {
                this.kehone()
              }} onMouseOut={() => {
                this.kehoneout()
              }}><span className="iconfont" style={{fontSize: '55px'}}>&#xe608;</span> <span>客服电话</span></p>
                <span className={kefufalg == true ? classes.showdianhua : classes.hidedianhua}>0351-2334100</span>
              </h5>
              <h5 className={classes.wxinlist}>
                <p>   <span onMouseOver={() => {
                  this.Guanfang()
                }} onMouseOut={() => {
                  this.Guanfangout()
                }} className="iconfont" style={{fontSize: '55px'}}>&#xe605;</span> <span>官方微信</span>
                  <img className={wexxing == true ? classes.wxingshow : classes.wxinghide} src={guanfang}></img>
                </p>
              </h5>

            </div>


            <div className={this.state.falg == true ? classes.Customer1 : classes.Customer}>
            <span className={classes.close} onClick={() => {
              this.close()
            }}>x</span>
              <div className={classes.kefu0}>
                <span><img className={classes.kfpic} src={kepic}></img></span>
                <ol className={classes.bubble}>
                  <li> 您好，山西瑞海节能网控电热集中供暖有限公司欢迎您！瑞海热控是一家集研发、实施、安装、运行、售后服务于一体的高新技术企业，依托自主研发的互联网云控制系统，为客户提供智能化、一体化冷暖服务。
                    您好，欢迎来到瑞海热控！请您选择想要咨询的内容！
                  </li>
                </ol>

              </div>
              <div className={classes.kefu}>
                <span><img src={kepic}/></span>
                <ol className={classes.bubble2}>
                  <li>
                    <h4 className={classes.liaxi}>联系电话: 0351-2334100</h4>
                  </li>
                  <li>
                    <p className={classes.ceshi} onClick={() => {
                      this.backchat()
                    }}>联系人工客服QQ</p>
                  </li>
                </ol>
              </div>
              <div className={classes.kefu}>
                <span><img src={kepic}></img></span>
                <ol className={classes.bubble2}>
                  <li>
                    <h4 className={classes.hot}>热门问题</h4>
                    <p className={classes.konzhi} onClick={() => {
                      this.backtranslate()
                    }}>智慧控制系统</p>

                    <h5 className={classes.zhihui}> {this.state.supplyfalg == false ?
                      <p style={{display: "flex", alignItems: "center"}} onClick={() => {
                        this.supply()
                      }}><span className="iconfont" style={{display: "flex", alignItems: "center"}}><b style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: '19px',
                        marginLeft: '-7px',
                        fontWeight: "normal",
                        color: "#22222"
                      }}>&#xe603;</b><b className={classes.dian} style={{fontWeight: "normal"}}>冷暖一体化服务</b></span></p> :
                      <p onClick={() => {
                        this.supply()
                      }}><span style={{display: "flex", alignItems: "center"}} className="iconfont"> <b style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: '19px',
                        marginLeft: '-7px',
                        fontWeight: "normal",
                        color: '#22222'
                      }}>&#xe67e;</b><b className={classes.dian} style={{fontWeight: "normal"}}>冷暖一体化服务</b></span></p>}
                      <p className={this.state.supplyfalg == true ? classes.supplyshow : classes.supplyhide}><b
                        onClick={() => {
                          this.backGas()
                        }}><i className={classes.dian_diandian}>.</i><i
                        className={classes.dian_list}>燃气锅炉一体化解决方案</i></b><b onClick={() => {
                        this.backElectric()
                      }}><i className={classes.dian_diandian}>.</i><i className={classes.dian_list}>电采暖一体化解决方案</i></b><b
                        onClick={() => {
                          this.backconditioner()
                        }}><i className={classes.dian_diandian}>.</i><i className={classes.dian_list}>空调整体服务</i></b></p>
                    </h5>


                  </li>
                </ol>
              </div>
            </div>
          </div>
  }
}
export default  withRouter(Customer)