import React, { useState } from 'react';
import classes from "./App.module.scss";
import NavBar from "./components/Navigation/NavBar/NavBar";
import Footer from "./components/Footer/Footer";
import Legal from "./components/Legal/Legal";
import Customer from './components/customer/customer'
import RouterView from './router'
import {  withRouter } from "react-router-dom";
class App extends React.Component {
  constructor(props) {
    super(props)

  }
  render() {
      return (
        <div>
      <div className={classes.App} id="app" >
         <NavBar />
         <Customer />
         <RouterView/>
      <Footer />
      <Legal />
    </div>
     </div>
  );
    }
}
export default withRouter(App)
