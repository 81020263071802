import React from "react";
import classes from "./productPage.module.scss";
import cables from "../../assets/cables.png";
import plane from "../../assets/plane.png";
import chairs from "../../assets/chairs.png";
import towerCrane from "../../assets/towerCrane.png";
import Layout from "../../hoc/Layout/Layout";
import Jumbotron from "../../components/Jumbotron/Jumbotron";
import pipline from "../../assets/productPage/liquid_transport_pipline.png";
import fireHydrant from "../../assets/productPage/fire_hydrant.png";
import ptc from "../../assets/productPage/ptc.png";
import dianbanre from "../../assets/productPage/dianbanrezhikong.png";
import guandao from "../../assets/productPage/dianbanre_banner1.png"
import xiaofang from "../../assets/productPage/dianbanre_banner2.png"
import { Link } from "react-router-dom";
import 电伴热系统图 from "../../assets/productPage/电伴热系统图_03.png"
import 应用场景 from "../../assets/productPage/应用场景2_03.png"
import yingyon2 from "../../assets/productPage/yingyon2.1.jpg"
import dianbrezhikon from "../../assets/imgs/fuwu/1.jpg"
import YINYON from "../../assets/productPage/YINYON.jpg"
import headbanner2 from "../../assets/headbanner2.mp4"
const productPagedianbanre = () => {
  return (
    <Layout>
    <a href="/products/heat-cable"><video src={headbanner2} width="100%" height="400px"
       x5-playsinline="" playsinline="" webkit-playsinline=""
     loop autoPlay muted></video></a>
      <main className={classes.Main}>
        <div className={classes.ProductContainer}>
          <h1>产品服务</h1>
          <ul className={classes.ProductList}>
            <li className={classes.ProductItem}>
              <Link to="/products/features">能源云平台</Link>
            </li>
            <li className={classes.ProductItem}>
              <Link to="/products/heat-cable">发热电缆一体化服务</Link>
            </li>
            <li className={classes.ProductItem} style={{border: "1px #f56a00 solid"}}>
              <Link to="/products/heat-power" style={{color:"#f56a00"}}>电伴热一体化服务</Link>
            </li>
            <li className={classes.ProductItem}>
              <Link to="/products/heat-air">
                空气源热泵一体化服务
              </Link>
            </li>
            <li className={classes.ProductItem}>
              <Link to="/products/gas-boiler">燃气锅炉一体化服务</Link>
            </li>
          </ul>
        </div>

        
         <section className={classes.CableSection}>
          <div className={classes.Header}>
            <a className={classes.Anchor} id="dianbanre"></a>
            <h2>智能电伴热系统</h2>
            <hr />
          </div>
          <div>
          <p className={classes.dbre_text}>瑞海电伴热防冻及保温系统采用自限温电热带或恒功率电热带为加热元件，对需要防冻及保温的管道、罐体、流体或其他工艺设备进行伴热或加热，补充流体损失掉的热量，使温度保持在允许的范围内，满足各种温度需求。</p>
            <p className={classes.Ambient1}><span></span><i>智能电伴热系统优点</i><span></span></p>
            
            <div className={classes.advantage}>
                <span className={classes.num1}>01</span> <h3 className={classes.num_text}> <span>差异定制，适用性强</span> <p>可根据客户不同需求，差异化以及精致性提供电伴热供应,针对不同场景的温度控制和绝缘保护以及防爆保护等方面要求,提供性价比最高的整体方案。</p> </h3> 
            </div>
              
            <div className={classes.advantage}>
                <span className={classes.num2}>02</span> <h3 className={classes.num_text}> <span>质量严选，经济实用</span> <p>瑞海放弃了化工成品绝缘料，采用自制绝缘材料,不含油性添加剂,无分子渗透,保证辐照交联后的芯带分子结构稳定无破坏。设备实用寿命长达10年以上,建成后将极大降低人力成本,相比蒸汽伴热运行可省钱100%,设备会根据实际使用情况,保证约60%的消耗,40%处于保温不工作状态。</p> </h3> 
            </div>
              
            <div className={classes.advantage1}>
                <span className={classes.num3}>03</span> <h3 className={classes.num_text}> <span>自动测温，智能调控</span> <p>瑞海热控的自限温伴热技术能够根据需求,自动限制伴热带的温度,并随被加热体的温度自动调节输出功率。瑞海热控的温度、湿度、气压等传感器能自动监测环境特征,自动判断并实施最适合受热体的加热计划,满足受热体升温、保温、防冻等各种需求。</p> </h3> 
            </div>
         
         </div>
        </section>
     
        <section className={classes.CableSection}>
          <div className={classes.Header}>
            <a className={classes.Anchor} id="dianbanre"></a>
            <h2>电伴热智控</h2>
            <hr />
          </div>

          <div className={classes.Text}>
            <img src={dianbrezhikon} loading="lazy" className={classes.CablesImage} />
            <p>
              瑞海热控的电伴热系统有温度梯度小、热稳定时间较长、无污染、使用寿命长等的特点，广泛应用于工业伴热如石油化工、电厂脱硫、隧道地铁、罐体管道、船舶、仪表等和商业伴热如消防管道、天沟融雪、水管防冻、道路融雪、混凝土发热、热水管保养等。瑞海热控的电伴热合作伙伴拥有着领先的技术和多项专利，在电伴热温度控制、绝缘保护、防爆保护等方面一直位于市场的最上游。结合合作伙伴技术上的优势，瑞海热控在电伴热的智能控制上有了新的突破。瑞海热控的自限温伴热技术能够根据需求，自动限制伴热带的温度，并随被加热体的温度自动调节输出功率。瑞海热控的温度、湿度、气压等传感器能自动检测环境特征，自动判断并实施最适合受热体的加热计划，满足受热体升温、保温、防冻等各种需求。瑞海热控的大数据天气预测系统还可以对极寒，暴雪等极端天气有自动预警功能。
            </p>
          </div>
        </section>
        <section className={classes.GallerySection}  style={{marginTop:'0'}}>
          <div className={classes.Header}>
            <h2>经典应用场景</h2>
            <hr />
          </div>
          <div className={classes.Gallery}>
            <figure>
              <img src={pipline} />
              <figcaption>
                液体运输管道，需要管道温度恒定保证液体的流动性。输送管道距离长，所处环境复杂，容易受热不均。瑞海热控的管道伴热带设定液体流流动所需的温度为额定温度，可以及时补充流体损失的热量，使得温度保持在允许范围内。可以折叠交叉的特性，更加安全可靠、发热均匀。节能省钱，伴热花费仅是蒸汽伴热的一半。
              </figcaption>
            </figure>
            <figure>
              <img src={fireHydrant} />
              <figcaption>
                市政消防是人身和财产安全保障的重中之重&nbsp;，在遇到火警时必须保证消防管道供水通畅。而因为其使用不频繁，所以需要在一款低功耗情况下还能保证管道供水在极端环境下顺畅的伴热网络。瑞海热控的管道伴热带设定智能启动温度，配合物联网集中控制技术，根据管道的温度，进行局部的，区域的补温。实现长时间低能耗智能伴热。
              </figcaption>
            </figure>
            <figure>
              <img src={ptc} />
              <figcaption>
                建筑和天沟融雪需要伴热带耐腐蚀、升温快&nbsp;。瑞海热控的融雪伴热带使用PTC发热材料，耐腐蚀、升温迅速。特殊的排线工艺，可以给室外狭窄的复杂的地形伴热，铺设轻松&nbsp;，施工方便和维护成本低。结合瑞海热控的温度和天气传感器，根据预设气温、湿度&nbsp;、降雪等关键参数实现自动运行、节能降耗的目的。
              </figcaption>
            </figure>      
          </div>
          <p> <img className={classes.YINYON} src={YINYON}></img></p>
           <p className={classes.YINYON_text}><span>隧道地铁</span><span>天沟融雪</span><span>石油化工</span><span>消防管道</span><span>水管防冻</span><span>电厂脱硫</span></p>
        </section>
        <section className={classes.AboutSection}>
          <div className={classes.Header}>
            <h2>相关解决方案</h2>
            <hr />
          </div>
          <a href="/solutions/tunnel"><img className={classes.Banner} src={guandao} alt="" height="250px" width="1152px"/></a>
          <a href="/solutions/firefighting"><img className={classes.Banner} src={xiaofang} alt="" height="250px" width="1152px"/></a>
        </section>
      </main>
    </Layout>
  );
};

export default productPagedianbanre;
