import React from "react";
import classes from "./Dropdowns.module.scss";
import Dropdown from "./Dropdown/Dropdown";
const Dropdowns = (props) => {
  let dropdowns;

  if (Array.isArray(props.dropdownMenu[0])) {
    dropdowns = props.dropdownMenu.map((menu, index) => {
      return (
        <Dropdown
          menuName={menu[0]}
          items={menu.slice(1)}
          key={menu[0]}
          paths={props.dropdownMenuPaths[index]}
        />
      );
    });
  } else {
    dropdowns = (
      <Dropdown items={props.dropdownMenu} paths={props.dropdownMenuPaths} />
    );
  }

  return <ul className={classes.Dropdowns}>{dropdowns}</ul>;
};

export default Dropdowns;
