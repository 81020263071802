import React from "react";
import Layout from "../../hoc/Layout/Layout";
import classes from "./casesPage.module.scss";
import Jumbotron from "../../components/Jumbotron/Jumbotron";

import 北京假日阳光别墅供暖升级项目 from "../../assets/casesPage/北京假日阳光别墅供暖升级项目.jpg";
import 国家电网太原分公司高层宿舍集中供暖项目 from "../../assets/casesPage/国家电网太原分公司高层宿舍集中供暖项目.jpg";
import 山西忻州同煤大厦智能化供暖项目 from "../../assets/casesPage/tonmei.jpg";
import 沈阳新世纪家园别墅智能化供暖项目 from "../../assets/casesPage/沈阳新世纪家园别墅智能化供暖项目.jpg";
import 太原学院新校区综合供暖项目1 from "../../assets/casesPage/太原学院新校区综合供暖项目1.jpg";
import 太原学院新校区综合供暖项目2 from "../../assets/casesPage/太原学院新校区综合供暖项目2.jpg";

const casesPage = () => {
  return (
    <Layout>
      <Jumbotron page="subPage" />
      <main className={classes.Main}>
        <section className={classes.CableSection} style={{ marginTop: "50px" }}>
          <div className={classes.Header}>
            <a className={classes.Anchor} id="solution"></a>
            <h2>太原学院新校区综合供暖项目</h2>
            <hr />
          </div>

          <div className={classes.Text}>
            <img
              src={太原学院新校区综合供暖项目1}
              style={{ margin: "18px 15%", width: "70%" }}
              loading="lazy"
            />
            <p
              style={{
                marginTop: "18px",
                textAlign: "justify",
                wordBreak: "break-all",
              }}
            >
              项目特点:
              太原学院新校区位于太原市综改示范区大昌南路18号，前身是太原大学。自2011年新校区建成使用至今，集中控制电采暖建筑面积约22万平方米。包含教学楼、学生公寓楼、食堂等多种功能类型建筑。
            </p>

            <p
              style={{
                marginTop: "18px",
                textAlign: "justify",
                wordBreak: "break-all",
              }}
            >
              项目难点:
              单体项目建筑面积巨大、建筑种类复杂、供暖要求不同、教学楼人流量大散热严重、协调统一管理建筑群有非常高的难度、附近没有供热站。
            </p>

            <p
              style={{
                marginTop: "18px",
                textAlign: "justify",
                wordBreak: "break-all",
              }}
            >
              使用效果:
              由山西瑞海负责安装运行的太原学院新校区电采暖工程平稳运行多年，供暖效果良好，维修费用低廉。且供暖控制灵活，故障率低，学校师生反映良好，管理部门很满意。
            </p>
          </div>
        </section>
        <section className={classes.CableSection}>
          <div className={classes.Header}>
            <a className={classes.Anchor} id="project"></a>
            <h2>国家电网太原分公司高层宿舍集中供暖项目</h2>
            <hr />
          </div>

          <div className={classes.Text}>
            <img
              src={国家电网太原分公司高层宿舍集中供暖项目}
              style={{ margin: "18px 15%", width: "70%" }}
              loading="lazy"
            />
            <p
              style={{
                marginTop: "18px",
                textAlign: "justify",
                wordBreak: "break-all",
              }}
            >
            项目特点: 太原供电局高层住宅楼位于太原市亲贤街与长治路交叉口。单体铺设面积达2万平米，采用电采暖集中控制系统，铺设6年，运营良好。
            </p>

            <p
              style={{
                marginTop: "18px",
                textAlign: "justify",
                wordBreak: "break-all",
              }}
            >
           项目难点: 建筑材料不过硬，保温差。单体项目建筑面积大。
            </p>

            <p
              style={{
                marginTop: "18px",
                textAlign: "justify",
                wordBreak: "break-all",
              }}
            >
              使用效果:
             由山西瑞海负责安装运行的太原供电局高层住宅楼电采暖工程已经使用运行11年，供暖效果良好，维修费用低、操作简便、住户对供暖效果反映良好。
            </p>
          </div>
        </section>
        <section className={classes.CableSection}>
          <div className={classes.Header}>
            <a className={classes.Anchor} id="mode"></a>
            <h2>北京假日阳光别墅供暖升级项目</h2>
            <hr />
          </div>

          <div className={classes.Text}>
            <img
              src={北京假日阳光别墅供暖升级项目}
              style={{ margin: "18px 15%", width: "70%" }}
              loading="lazy"
            />
            <p
              style={{
                marginTop: "18px",
                textAlign: "justify",
                wordBreak: "break-all",
              }}
            >
              项目特点:
             位于北京市延庆区，建筑类型为连体平层别墅。别墅坐落在八达岭长城附近，昼夜温差大。建筑物散热严重。此外假日阳光别墅因为业主居住时间长度不一：有的业主长期居住，冬季需要持续供暖；有的业主只会短期居住，一年就居住一两星期。
            </p>

            <p
              style={{
                marginTop: "18px",
                textAlign: "justify",
                wordBreak: "break-all",
              }}
            >
              项目难点:
              昼夜温差大、建筑物散热严重、距离供热站远、对供暖时间有自定义需求。
            </p>

            <p
              style={{
                marginTop: "18px",
                textAlign: "justify",
                wordBreak: "break-all",
              }}
            >
              使用效果:
             由山西瑞海负责安装运行的北京假日阳光别墅电采暖工程平稳运行多年，供暖效果良好，采暖费用收费灵活合理。供暖随开随停，故障率低，受到业主和物业部门管理部门的好评。
            </p>
          </div>
        </section>
        <section className={classes.CableSection}>
          <div className={classes.Header}>
            <a className={classes.Anchor} id="research"></a>
            <h2>山西忻州同煤大厦智能化供暖项目</h2>
            <hr />
          </div>

          <div className={classes.Text}>
            <img
              src={山西忻州同煤大厦智能化供暖项目}
              style={{ margin: "18px 15%", width: "70%" }}
              loading="lazy"
            />
            <p
              style={{
                marginTop: "18px",
                textAlign: "justify",
                wordBreak: "break-all",
              }}
            >
              项目特点:
            位于山西省忻州市忻府区和平西街。忻州同煤大厦是集办公、会议于一体的综合办公楼，采用电采暖集中控制系统控制，发热体采用单导恒功发热电缆。单体面积7000余平米。建成使用已有十余年时间。
            </p>

            <p
              style={{
                marginTop: "18px",
                textAlign: "justify",
                wordBreak: "break-all",
              }}
            >
              项目难点:
             单体项目建筑面积巨大、对供暖时间有自定义需求。
            </p>

            <p
              style={{
                marginTop: "18px",
                textAlign: "justify",
                wordBreak: "break-all",
              }}
            >
              使用效果:
            由山西瑞海负责安装运行的山西忻州同煤大厦电采暖工程安全运行多年，供暖效果良好，维修费用低、操作简便。各个房间和其他供暖部位可随时调整供暖时间与供暖温度，使用十分方便。
            </p>
          </div>
        </section>
        <section className={classes.CableSection}>
          <div className={classes.Header}>
            <a className={classes.Anchor} id="advanage"></a>
            <h2>沈阳新世纪家园别墅智能化供暖项目</h2>
            <hr />
          </div>

          <div className={classes.Text}>
            <img
              src={沈阳新世纪家园别墅智能化供暖项目}
              style={{ margin: "18px 15%", width: "70%" }}
              loading="lazy"
            />
            <p
              style={{
                marginTop: "18px",
                textAlign: "justify",
                wordBreak: "break-all",
              }}
            >
              项目特点:
             位于沈阳沈河区核心地段，为用户量身打造采暖方式。别墅建筑物散热严重。业主居住房间和时间长度不一：许多业主表示希望能自己调节房间温度和开关状态来节省供暖费。
            </p>

            <p
              style={{
                marginTop: "18px",
                textAlign: "justify",
                wordBreak: "break-all",
              }}
            >
              项目难点:
              建筑物散热严重、距离供热站远、对供暖时间有自定义需求。
            </p>

            <p
              style={{
                marginTop: "18px",
                textAlign: "justify",
                wordBreak: "break-all",
              }}
            >
              使用效果:
            由山西瑞海负责安装运行的沈阳新世纪家园别墅电采暖工程平稳运行多年，供暖效果良好，采暖费用收费灵活合理。供暖随开随停，故障率低，维修速度快，受到业主和物业部门管理部门的好评。
            </p>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default casesPage;
