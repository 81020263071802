const MachineData = {
    /**
     * 顺序必须保持 fans evaporator compressor connected throttle control-box condenser external-machine
     */
    grounding: {
        image: require("../assets/images/grounding.png"),
        init_state: {
            "x_state": -90,
            "y_state": -0,
        }
    },
    fans: {
        image: require("../assets/images/fans.png"),
        init_state: {
            "x_state": -87,
            "y_state": -3,
        },
        end_state: {
            "x_state": -20,
            "y_state": -65,
        }
    },
    evaporator: {
        image: require("../assets/images/evaporator.png"),
        init_state: {
            "x_state": -100,
            "y_state": -10,
        },
        end_state: {
            "x_state": -185,
            "y_state": -250,
        }
    },
    compressor: {
        image: require("../assets/images/compressor.png"),
        init_state: {
            "x_state": -99,
            "y_state": -5,
        },
        end_state: {
            "x_state": -378,
            "y_state": -40,
        }
    },
    connected: {
        image: require("../assets/images/connected.png"),
        init_state: {
            "x_state": -100,
            "y_state": -10,
        },
        end_state: {
            "x_state": -301,
            "y_state": -185,
        }
    },
    throttle: {
        image: require("../assets/images/throttle.png"),
        init_state: {
            "x_state": -98,
            "y_state": -7,
        },
        end_state: {
            "x_state": -300,
            // -190
            "y_state": -190,
        }
    },
    "control_box": {
        image: require("../assets/images/control-box.png"),
        init_state: {
            "x_state": -90,
            "y_state": -0,
        },
        end_state: {
            "x_state": 120,
            "y_state": -25,
        }
    },
    "condenser_after": [
        {
            image: require("../assets/images/condenser-03.png"),
            init_state: {
                "x_state": -85,
                "y_state": -3,
            },
            end_state: {
                "x_state": 137,
                "y_state": -6,
            }
        },
        {
            image: require("../assets/images/condenser-04.png"),
            init_state: {
                "x_state": -90,
                "y_state": -5,
            },
            end_state: {
                "x_state": 134,
                "y_state": -9,
            }
        }
    ],
    "external_machine_before": {
        image: require("../assets/images/external-machine-02.png"),
        init_state: {
            "x_state": -87,
            "y_state": -3,
        }
    },
    "external_machine_aside": {
        image: require("../assets/images/external-machine-03.png"),
        init_state: {
            "x_state": -91,
            "y_state": -0,
        }
    },
    "condenser_before": [
        {
            image: require("../assets/images/condenser-02.png"),
            init_state: {
                "x_state": -80,
                "y_state": -5.5,
            },
            end_state: {
                "x_state": 150,
                "y_state": -17.5,
            }
        },
        {
            image: require("../assets/images/condenser-01.png"),
            init_state: {
                "x_state": -93,
                "y_state": -8,
            },
            end_state: {
                "x_state": 150,
                "y_state": -20,
            }
        }],
    "external_machine": {
        image: require("../assets/images/external-machine.png"),
        init_state: {
            "x_state": -90,
            "y_state": -0,
        }
    }
}

const getData = () => {
    let list = [];
    for (let data in MachineData) {
        if (!Array.isArray(MachineData[data])) {
            list.push(MachineData[data]);
        } else {
            list = list.concat(MachineData[data])
        }
    }
    return list.map(item => {
        item.state = {
            "x_state": item.init_state.x_state,
            "y_state": item.init_state.y_state
        }
        return item;
    })
}

export { getData };